<template>
    <main id="main" class="main">
      <div class="main-container-row">
        <div class="content">
        <div style="text-align:center;padding-top:200px">
            <h1 :style="{'font-size':'2.0em', 'display': state.progressView ? 'block' : 'none' }">카카오 로그인 진행중입니다.</h1>
            <div :style="{'font-size':'1.5em', 'line-height': '50px', 'display': !state.progressView ? 'block' : 'none'}">
              <p>회원가입이 완료되었습니다.</p>
              <p>카카오로부터 제공받은 이메일 : {{ state.email }}</p>
              <p>이메일로 가격 변동 알림이 발송됩니다.</p>
              <p><a href="/"><button type="button" class="btn btn-lg">메인으로</button></a></p>
            </div>
        </div>
        </div>
      </div>
    </main>
</template>
<script>
import axios from '@/plugin/axios'
import commonUtil from '@/util/CommonUtil'
import { reactive, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
export default {
  setup () {
    const API_SERVER = process.env.VUE_APP_API_SERVER
    const serviceDomain = process.env.VUE_APP_SERVICE_DOMAIN
    const store = useStore()
    const route = useRoute()
    const code = route.query.code
    const error = route.query.error
    const rediretUrl = serviceDomain + '/oauth/kakao'
    const state = reactive({
      resultText: [], progressView: true, email: ''
    })
    onMounted(() => {
      if (error) {
        alert(route.query.error_description)
        return
      }
      getAccessToken()
    })
    const getAccessToken = async () => {
      try {
        const result = await axios.post(API_SERVER + '/api/public/kakaoAccessTokenProcess', { code: code, url: rediretUrl }, {
          withCredentials: true, headers: { 'Content-Type': 'application/json' }
        })
        store.commit('setJwtToken', result.data.accessToken)
        store.commit('setRefreshToken', result.data.refreshToken)
        if (result.data.registMember) {
          state.progressView = false
          state.email = commonUtil.parseJwt(result.data.accessToken).email
        } else {
          document.location.href = '/'
        }
      } catch (e) {
        console.log(e)
        alert('토큰 획득에 실패했습니다. - ' + e.response.data.message)
      }
    }
    return {
      state
    }
  }
}
</script>
