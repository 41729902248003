const parseToken = (token) => {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
  }).join(''))
  return JSON.parse(jsonPayload)
}
const leftPad = (value) => {
  if (value > 9) return value
  else return `0${value}`
}
export default {
  numberformat: (val) => {
    return String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  },
  addDate: (source, addDay) => {
    return source.setDate(source.getDate() + addDay)
  },
  dateFormatting: (source, addDay, delimiter = '-') => {
    source.setDate(source.getDate() + addDay)
    const year = source.getFullYear()
    const month = this.leftPad(source.getMonth() + 1)
    const day = this.leftPad(source.getDate())
    return [year, month, day].join(delimiter)
  },
  dateFormatting2: (initTime, addDay, delimiter = '-') => {
    const source = new Date()
    source.setTime(initTime)
    source.setDate(source.getDate() + addDay)
    const year = source.getFullYear()
    const month = leftPad(source.getMonth() + 1)
    const day = leftPad(source.getDate())
    return [year, month, day].join(delimiter)
  },
  dateFormatting3: (initTime, addDay, delimiter = '-') => {
    const source = new Date()
    source.setTime(initTime)
    source.setDate(source.getDate() + addDay)
    const year = source.getFullYear()
    const month = leftPad(source.getMonth() + 1)
    const day = leftPad(source.getDate())
    const hour = leftPad(source.getHours())
    const minute = leftPad(source.getMinutes())
    return [year, month, day].join(delimiter) + ' ' + [hour, minute].join(':')
  },
  pageCalculate: (pageable, totalPages, pageBlock) => {
    let pre = 0
    let next = 0
    const pageArr = []
    let last = false
    const minus = pageable.pageNumber % pageBlock
    const startPage = pageable.pageNumber + 1 - minus
    if (startPage >= pageBlock) pre = startPage - 1
    for (let i = 0; i < pageBlock; i++) {
      pageArr.push(startPage + i)
      if (startPage + i >= totalPages) {
        last = true
        break
      }
    }
    if (!last) next = pageArr[pageArr.length - 1] + 1
    return { pre: pre, pageArr: pageArr, currentPage: pageable.pageNumber + 1, next: next }
  },
  discountPercentCal: (price, percent) => {
    return Math.floor((price * (100 - percent) / 100) / 10) * 10
  },
  parseJwt: (token) => {
    if (!token) return {}
    return parseToken(token)
  },
  expireCheckJwt: (token) => {
    if (!token) return false
    return Number(parseToken(token).exp + '000') > Number(new Date().valueOf())
  },
  expireGenerate: (minute) => {
    return new Date().getTime() + (minute * 60 * 1000)
  },
  expireCheck: (time) => {
    return new Date().getTime() < time
  }
}
