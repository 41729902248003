<template>
  <header id="header" class="header">
    <div class="header-container">
      <a href="javascript:history.back();" class="header-back nav-item" style="display: none;"><img src="../assets/images/history_back.svg" alt="뒤로가기"></a>
      <router-link to="/"><h1 class="header-logo"><img src="../assets/images/logo_header.svg" alt="쿠알람"></h1></router-link>
      <nav class="nav">
        <a href="javascript:void(0)" class="nav-item search-mobile btn-search-show"><img src="../assets/images/btn_search.svg" alt="검색"></a>
        <!-- .badge-new 클래스 추가 -->
        <router-link to="/price" class="nav-item"><!--badge-new--><img src="../assets/images/btn_alarm.svg" alt="알림"></router-link>
        <a href="javascript:void(0)" class="nav-item gnb"><img src="../assets/images/btn_hambuger.svg" alt="메뉴"></a>
        <div class="nav-list-container" style="display: none;">
          <ul class="nav-list">
            <li class="nav-list-item"><a href="javascript:void(0)" @click="myGo('zzim')">찜 보관함</a></li>
            <li class="nav-list-item"><a href="javascript:void(0)" @click="myGo('zzim')">최근 본 상품</a></li>
            <li class="nav-list-item"><a href="javascript:void(0)" @click="myGo('zzim')">최근 검색어</a></li>
            <li class="nav-list-item"><a href="javascript:void(0)" @click="myGo('zzim')">인기 검색어</a></li>
            <li class="nav-list-item"><a href="javascript:void(0)" @click="myGo('price')">가격 변동 알림함</a></li>
            <li v-if="loginStatus" class="nav-list-item"><a href="javascript:void(0)" @click="myGo('myInfo')">나의 정보</a></li>
            <li v-if="loginStatus" class="nav-list-item"><a href="javascript:void(0)" @click="logout()">로그아웃</a></li>
          </ul>
        </div>
      </nav>
      <div class="header-search">
        <label for="searchKeyword" class="search-label btn-search-show text-truncate"><span v-if="state.keyword === ''">쿠팡에서 판매되는 제품의 가격변동과 구입하기 좋은 가격을 알려드립니다.</span>
          <span v-if="state.keyword !== ''">{{ state.keyword }}</span></label>
        <!-- 상품 검색 -->
        <div class="header-search-block" style="display: none;"> <!-- show, hide -->
          <div class="header-search-container">
            <div class="mobile-search-title">
              <h1><img src="../assets/images/logo_header.svg" alt="뒤로가기"></h1>
              <p>쿠팡에서 판매되는 제품의 가격변동과 <br> 구입하기 좋은 가격을 알려드립니다.</p>
            </div>
            <fieldset>
              <form v-on:submit.prevent="search">
              <input type="text" id="searchKeyword" class="header-search-input" placeholder="검색어를 입력하세요." v-model="state.keyword">
              <button type="button" class="btn-header-search" @click="() => search()">
                <img src="../assets/images/btn_search_sm.png" alt="검색">
              </button>
              </form>
            </fieldset>
            <div class="header-search-keyword">
              <div class="keyword-recent">
                <h2 class="keyword-title">최근 검색어</h2>
                <ul>
                  <li v-for="(keyword , i) in state.myKeyword" :key="i"><a href="javascript:void(0)" class="text-truncate" @click="() => directSearch(keyword.keyword)">{{ keyword.keyword }}</a><!--button class="btn-icon"><i class="bi bi-x"></i></button--></li>
                </ul>
              </div>
              <div class="kerword-rank">
                <h2 class="keyword-title">인기 검색어</h2>
                <ol>
                  <li v-for="(keyword , i) in state.bestKeyword" :key="i"><span class="rank-number">{{ i+1 }}</span><a href="javascript:void(0)" class="text-truncate" @click="() => directSearch(keyword.keyword)">{{ keyword.keyword }}</a></li>
                </ol>
              </div>
            </div>
            <button type="button" class="btn-modal-close btn-icon btn-text-white btn-search-hide"><i class="bi bi-x-lg"></i></button>
          </div>
        </div>
      </div>
    </div>
  </header>
  <router-view/>
  <footer id="footer" class="footer">
    <div class="footer-container">
      <ul class="disc">
        <li>쿠알람이 제공하는 제품의 가격 정보는 수시로 변경될 수 있으니 쿠팡에서 실제 가격을 확인 후 구매해주세요!</li>
        <li>웹 페이지 내 바로가기 링크을 통한 구매에 대해서는 쇼핑몰로부터 수수료를 받을 수 있습니다.</li>
      </ul>
      <ul style="margin-top:10px;margin-bottom: 20px">
        <li style="float: left"><router-link to="/accessTerms">이용약관</router-link></li><li style="float: left;margin-left:20px;"><router-link to="/private">개인정보 취급방침</router-link></li>
      </ul>
    </div>
  </footer>
  <ModalView/>
  <MyInfoModalView/>
  <a href="#" id="scroll-top" class="scroll-top d-flex align-items-center justify-content-center"><i class="bi bi-arrow-up-short"></i></a>
</template>
<script>
import axios from '@/plugin/axios'
import { onBeforeMount, onMounted, reactive, watch, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import commonUtil from '@/util/CommonUtil'
import ModalView from './views/SnsLoginModalView.vue'
import MyInfoModalView from './views/MyInfoModalView.vue'
import tokenUtil from '@/mixin/tokenUtil'
export default {
  components: { ModalView, MyInfoModalView },
  setup () {
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    const { tokenCheck } = tokenUtil()
    const loginStatus = computed(() => store.getters.getLoginStatus)
    let firstLoad = false
    const API_SERVER = process.env.VUE_APP_API_SERVER
    const state = reactive({
      keyword: '', bestKeyword: [], myKeyword: []
    })
    watch(route, (cur, pre) => {
      if (firstLoad) {
        firstLoad = false
        return
      }
      searchKeywordLoad()
    })
    onMounted(() => {
      document.getElementsByClassName('btn-search-show').item(1).addEventListener('click', (e) => {
        document.getElementsByClassName('header-search-block').item(0).style.display = 'block'
      })

      document.getElementsByClassName('btn-search-show').item(0).addEventListener('click', (e) => {
        document.getElementsByClassName('header-search-block').item(0).style.display = 'block'
      })

      document.getElementsByClassName('btn-search-hide').item(0).addEventListener('click', (e) => {
        document.getElementsByClassName('header-search-block').item(0).style.display = 'none'
      })

      document.getElementsByClassName('gnb').item(0).addEventListener('click', (e) => {
        document.getElementsByClassName('nav-list-container').item(0).style.display = 'block'
      })

      document.addEventListener('mouseup', (e) => {
        if (e.target.closest('.header-search-block') == null) {
          closepop(['header-search-block'])
        }

        if (e.target.closest('.nav-list-container') == null) {
          closepop(['nav-list-container'])
        }
      })
      firstLoad = true
      searchKeywordLoad()
      /* 레퍼저장 */
      const referrer = document.referrer
      const currentHost = document.location.host
      /*
      console.log(document.location.host)
      console.log('referrer : ' + referrer)
      */
      if (referrer && referrer !== '' && referrer.indexOf(currentHost) === -1) {
        const params = new URLSearchParams()
        params.append('url', referrer)
        axios.post(API_SERVER + '/api/public/entryReferrerInsert', params, {
          withCredentials: true, headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        })
      }
    })
    onBeforeMount(() => {
      /* 파트너 코드 루틴 */
      const urlParams = new URLSearchParams(document.location.search)
      if (urlParams.has('ptn')) {
        console.log('ptn code : %s', urlParams.get('ptn'))
        store.commit('setPtnInfo', { ptn: urlParams.get('ptn'), expire: commonUtil.expireGenerate(60 * 24) })
      }
      tokenCheck()
      setInterval(() => {
        /* 토큰 재 등록 유틸 */
        tokenCheck()
      }, 1000 * 60)
    })
    const searchKeywordLoad = async () => {
      const result = await axios.get(API_SERVER + '/api/public/keywordList', {
        withCredentials: true, headers: { 'Content-Type': 'application/json' }
      })
      if (loginStatus.value) {
        state.myKeyword = result.data.my
      } else {
        /*
        console.log('app keywordList ' + store.state.keywordList.length)
        */
        state.myKeyword = store.state.keywordList
      }
      state.bestKeyword = result.data.best
    }
    const closepop = (eleClasses) => {
      eleClasses.forEach((e) => {
        document.getElementsByClassName(e).item(0).style.display = 'none'
      })
    }
    const myGo = (go) => {
      closepop(['nav-list-container'])
      if (!loginStatus.value) {
        store.commit('setModalPop', true)
        state.loginFlag = false
        if (route.path.startsWith('/zzim') || route.path.startsWith('/price')) {
          router.push({
            path: '/'
          })
        }
      } else {
        if (go === 'myInfo') {
          store.commit('setMyInfoModalPop', true)
          go = 'zzim'
        }
        router.push({
          path: '/' + go
        })
      }
    }
    const logout = () => {
      closepop(['nav-list-container'])
      store.commit('setJwtToken', '')
      store.commit('setRefreshToken', '')
      /*
      router.push({
        path: '/'
      })
      */
      document.location.href = '/'
    }

    const directSearch = (keyword) => {
      state.keyword = keyword
      search()
    }

    const search = () => {
      if (state.keyword.length < 2) {
        alert('검색어는 최소 2글자 이상입니다.')
        return
      }
      document.getElementsByClassName('header-search-block').item(0).style.display = 'none'
      router.push({ path: '/search/' + state.keyword })
    }
    return {
      commonUtil, myGo, logout, state, search, directSearch, loginStatus
    }
  }
}
</script>
<style>
@import "../assets/css/style.css";
</style>
