import { createRouter, createWebHistory } from 'vue-router'
import MainView from '../views/MainView.vue'
import DetailView from '../views/DetailView.vue'
import ZzimView from '../views/ZzimView.vue'
import PriceView from '../views/PriceView.vue'
import ProductsListView from '../views/ProductsListView.vue'
import KeywordSearchView from '../views/KeywordSearchView.vue'
import KakaoRedirectView from '../views/KakaoRedirectView.vue'
import NaverRedirectView from '../views/NaverRedirectView.vue'
import AccessTermsView from '../views/AccessTermsView.vue'
import PrivateView from '../views/PrivateView.vue'
import EventProductsView from '../views/EventProductsView.vue'

const routes = [
  {
    path: '/',
    name: 'main',
    component: MainView
  }, {
    path: '/detail/:idxnum',
    name: 'detail',
    component: DetailView,
    props: false
  }, {
    path: '/zzim',
    name: 'zzim',
    component: ZzimView,
    props: false
  }, {
    path: '/price',
    name: 'price',
    component: PriceView,
    props: false
  }, {
    path: '/list/:category',
    name: 'list',
    component: ProductsListView,
    props: false
  }, {
    path: '/search/:keyword',
    name: 'keywordSearch',
    component: KeywordSearchView,
    props: true
  }, {
    path: '/oauth/kakao',
    name: 'kakaoRedirect',
    component: KakaoRedirectView
  }, {
    path: '/oauth/naver',
    name: 'naverRedirect',
    component: NaverRedirectView
  }, {
    path: '/accessTerms',
    name: 'accessTerms',
    component: AccessTermsView
  }, {
    path: '/private',
    name: 'private',
    component: PrivateView
  }, {
    path: '/event',
    name: 'event',
    component: EventProductsView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
