import axios from '@/plugin/axios'
import store from '@/main/store/storage'
export default function tokenUtil () {
  function parseToken (token) {
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
    }).join(''))
    return JSON.parse(jsonPayload)
  }
  async function tokenCheck () {
    const API_SERVER = process.env.VUE_APP_API_SERVER
    const accessToken = store.state.jwtToken
    const refreshToken = store.state.refreshToken
    const currentNow = Number(new Date().getTime())
    if (!accessToken) return
    if (Number(parseToken(accessToken).exp + '000') - (60000 * 5) > currentNow) return
    if (Number(parseToken(refreshToken).exp + '000') < currentNow) return
    try {
      const result = await axios.post(API_SERVER + '/api/public/refreshTokenCheck', { refreshToken: refreshToken }, {
        withCredentials: true, headers: { 'Content-Type': 'application/json' }
      })
      store.commit('setJwtToken', result.data.accessToken)
      /*
      store.commit('setRefreshToken', result.data.refreshToken)
      console.log('token refresh complete')
      */
    } catch (e) {
      console.log(e)
    }
  }

  return {
    tokenCheck
  }
}
