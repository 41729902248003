<template>
  <main id="main" class="main">
    <div class="main-container-row">
      <SideCategory :choiceCategory="state.choiceCategory" @send-message="sendMessage"/>
      <div class="content">
        <!-- 최근 본 상품 -->
        <section class="mg-t0">
          <div class="section-title">
            <h2>추천순 / 할인률순</h2>
          </div>
          <div class="product-list">
            <div class="product-item" :key="ii" v-for="(product, ii) in state.productList">
                        <div class="product-img">
                            <router-link v-bind:to="'/detail/'+product.idxnum" target="_blank"><img
                                    v-bind:src="product.preloadImage"
                                    alt="상품이미지" class="thumbnail"></router-link>
                            <span class="product-badge discount left-top" v-if="product.preDiscount > 3">{{ product.preDiscount }}% <img src="../../assets/images/icon_down.svg"
                                    alt="하락"></span>
                            <!--span class="product-badge slodout right-top">품절임박</span-->
                        </div>
                        <div class="product-title"><router-link v-bind:to="'/detail/'+product.idxnum" target="_blank">{{ product.name }}</router-link></div>
                        <div class="product-price-origin" v-if="product.originPrice > 0"><span class="discount" v-if="product.discountPercent > 0">{{ product.discountPercent }}%</span> <span
                                        class="price-origin">{{ commonUtil.numberformat(product.originPrice) }}원</span></div>
                        <div class="product-price" v-if="product.salePrice > 0 && product.couponPrice == 0"><span class="price">{{ commonUtil.numberformat(product.salePrice) }}</span>원</div>
                        <div class="product-price" v-if="product.couponPrice > 0"><span class="price">{{ commonUtil.numberformat(product.couponPrice) }}</span>원</div>
                        <div class="product-review"><span class="review-score-bg"><span class="review-score"
                                        v-bind:style="{width: product.ratingAverage+'%'}"></span></span> <span class="review-count">({{ commonUtil.numberformat(product.ratingCnt) }})</span></div>
                        <!--div class="product-price-change">450원 하락, 하락추세, 3일간 하락률 3%</div>
                        <div class="product-category-list">생활용품 > 헤어/바디/세안</div-->
                        <div class="product-benefit">
                          <ul>
                            <!--li>최대 4% 카드 즉시 할인</li-->
                            <li>최대 {{ commonUtil.numberformat(product.saveMoney) }}원 적립</li>
                          </ul>
                          <button type="button" v-bind:class="{'btn-icon': true, 'btn-zzim-list': true, 'btn-zzim-list-active': product.zzim}" @click="() => zzim(product)">
                            <i class="bi bi-suit-heart-fill"></i> <span class="zzim-text">찜</span>
                          </button>
                        </div>
                    </div>
          </div>
          <div id="sentinel"></div>
          <!-- 데이터 없음 -->
          <!-- End 데이터 없음 -->
        </section>
        <!-- End 최근 본 상품 -->
       </div>
    </div>
  </main>
</template>
<script>
import axios from '@/plugin/axios'
import { reactive, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import commonUtil from '@/util/CommonUtil'
import SideCategory from './SideCategory.vue'
export default {
  components: {
    SideCategory
  },
  setup () {
    const API_SERVER = process.env.VUE_APP_API_SERVER
    const route = useRoute()
    const store = useStore()
    const choiceCategory = route.params.category
    let page = 0
    const state = reactive({
      choiceCategory: choiceCategory, productList: []
    })
    onMounted(() => {
      window.scrollTo(0, 0)
      const io = new IntersectionObserver((entries, observer) => {
        if (entries[0].intersectionRatio <= 0) return
        if (page > 0)loadPage(state.choiceCategory, 'R')
      })
      io.observe(document.getElementById('sentinel'))
    })
    const sendMessage = async (category) => {
      window.scrollTo(0, 0)
      page = 0
      loadPage(category, 'R')
    }
    const loadPage = async (category, sortType) => {
      try {
        const result = await axios.get(API_SERVER + '/api/public/categoryProductList?category=' + category + '&listSize=32&page=' + page + '&sortType=' + sortType, {
          withCredentials: true, headers: { 'Content-Type': 'application/json' }
        })
        if (page > 0) {
          state.productList = [...state.productList, ...result.data]
        } else {
          state.productList = result.data
        }
        state.choiceCategory = category
        page++
      } catch (e) {
        alert('자료 불러오기에 실패했습니다.')
        console.log(e)
      }
    }

    const zzim = async (product) => {
      if (!commonUtil.expireCheckJwt(store.state.jwtToken)) {
        store.commit('setModalPop', true)
        return
      }
      try {
        const result = await axios.get(API_SERVER + '/api/member/zzim?productIdxnum=' + product.idxnum, {
          withCredentials: true, headers: { 'Content-Type': 'application/json' }
        })
        if (result.data.result === 'PRESENT') {
          alert('이미 찜 등록됐습니다.')
        } else if (result.data.result === 'ADD') {
          product.zzim = true
          alert('찜 등록됐습니다.')
        }
      } catch (e) {
        if (e.response.status === 403) {
          store.commit('setModalPop', true)
        }
      }
    }
    /*
    const infiniteHandler = () => {
      console.log('lastNum %i , %s', page, state.choiceCategory)
      page++
    }
    */
    return {
      state, commonUtil, sendMessage, zzim
    }
  }
}
</script>
