<template>
  <header class="rocket-header">
    <p class="rocket-header-p1"><span class="rocket-header-s1">쿠</span><span class="rocket-header-s2">팡</span>&nbsp;&nbsp;
      <span class="rocket-header-s3">로켓배송</span></p>
      <div>
        <p class="rocket-header-p2" >가격 변동 확인하고 원하는 가격에 구매하세요.</p>
        <p class="rocket-header-p2" style="opacity: 0">상품이 원하는 가격 도달시에 이메일로 알려드려요</p>
      </div>
  </header>
</template>
<script>
import { onMounted, onUnmounted } from 'vue'
export default {
  setup () {
    let currentIndex = 0
    let intervalId
    onMounted(() => {
      intervalId = setInterval(() => {
        const transIndex = currentIndex === 0 ? 1 : 0
        const currentElement = document.getElementsByClassName('rocket-header-p2').item(currentIndex)
        const transElement = document.getElementsByClassName('rocket-header-p2').item(transIndex)
        currentElement.classList.remove('up-view')
        transElement.classList.remove('down-hide')
        currentElement.classList.add('down-hide')
        transElement.classList.add('up-view')
        currentElement.style.opacity = 0
        transElement.style.opacity = 1
        currentElement.style.transform = 'translate(-50%,0)'
        currentIndex = transIndex
      }, 5000)
    })
    onUnmounted(() => {
      clearInterval(intervalId)
    })
  }
}
</script>
<style>
.rocket-header {
  font-weight: 600;
  position: relative;
  text-align: center;
  width: 100%;
  height: 140px;
  left: 0px;
  color: rgb(97, 140, 123);
  margin: -25px 0px 30px 0px;
  border: solid 5px rgb(97, 140, 123);
  border-width: medium;
  border-radius: 20px;
  padding-bottom: 10px
}
.rocket-header-p1{
  vertical-align: middle;
}
.rocket-header-s1 {
  font-size: 3.0em;
}
.rocket-header-s2 {
  font-size: 3.0em;
}
.rocket-header-s3 {
  font-size: 2.8em;
}
.rocket-header-p2 {
  position:absolute;
  width: 100%;
  left: 50%;
  font-size: 2.5em;
  transform: translate(-50%, 0);
}
.up-view {
  animation: viewAnimation;
  animation-iteration-count: 1;
  animation-duration: 3s;
  animation: (shorthand);
}
.down-hide {
  animation: hideAnimation;
  animation-iteration-count: 1;
  animation-duration:3s;
  animation: (shorthand);
}
@keyframes viewAnimation {
  from {
    opacity: 0;
    transform:translate(-50%,+60px)
  }
  to {
    opacity: 1;
    transform:translate(-50%,0px)
  }
}

@keyframes hideAnimation {
  from {
    opacity: 1;
    transform:ttranslate(-50%,0px)
  }
  to {
    opacity: 0;
    transform:translate(-50%,-60px)
  }
}
.rocket-img {
  width:40px;
  margin-top:-20px;
}
@media screen and (max-width: 768px) {
.rocket-header-s1 {
  font-size: 1.9em;
}
.rocket-header-s2 {
  font-size: 1.9em;
}
.rocket-header-s3 {
  font-size: 1.7em;
}
.rocket-header-p2 {
  font-size: 1.1em;
}
.rocket-img {
  width:33px;
  margin-top:-15px;
}
.rocket-header {
  height: 80px;
}
@keyframes viewAnimation {
  from {
    opacity: 0;
    transform:translate(-50%,+35px)
  }
  to {
    opacity: 1;
    transform:translate(-50%,0px)
  }
}

@keyframes hideAnimation {
  from {
    opacity: 1;
    transform:ttranslate(-50%,0px)
  }
  to {
    opacity: 0;
    transform:translate(-50%,-35px)
  }
}

}
</style>
