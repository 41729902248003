<template>
  <main id="main" class="main">
    <div class="main-container-row">
      <div class="content">
        <!-- 최근 본 상품 -->
        <section class="mg-t0">
          <div class="section-title">
            <h2>추천순 / 할인률순</h2>
          </div>
          <div class="product-list">
            <div class="product-item" :key="ii" v-for="(product, ii) in state.productList">
                        <div class="product-img">
                            <router-link v-bind:to="'/detail/'+product.idxnum" target="_blank"><img
                                    v-bind:src="product.preloadImage"
                                    alt="상품이미지" class="thumbnail"></router-link>
                            <span class="product-badge discount left-top" v-if="product.preDiscount > 3">{{ product.preDiscount }}% <img src="../../assets/images/icon_down.svg"
                                    alt="하락"></span>
                            <!--span class="product-badge slodout right-top">품절임박</span-->
                        </div>
                        <div class="product-title"><router-link v-bind:to="'/detail/'+product.idxnum" target="_blank">{{ product.name }}</router-link></div>
                        <div class="product-price-origin" v-if="product.originPrice > 0"><span class="discount" v-if="product.discountPercent > 0">{{ product.discountPercent }}%</span> <span
                                        class="price-origin">{{ commonUtil.numberformat(product.originPrice) }}원</span></div>
                        <div class="product-price" v-if="product.salePrice > 0 && product.couponPrice == 0"><span class="price">{{ commonUtil.numberformat(product.salePrice) }}</span>원</div>
                        <div class="product-price" v-if="product.couponPrice > 0"><span class="price">{{ commonUtil.numberformat(product.couponPrice) }}</span>원</div>
                        <div class="product-review"><span class="review-score-bg"><span class="review-score"
                                        v-bind:style="{width: product.ratingAverage+'%'}"></span></span> <span class="review-count">({{ commonUtil.numberformat(product.ratingCnt) }})</span></div>
                        <!--div class="product-price-change">450원 하락, 하락추세, 3일간 하락률 3%</div>
                        <div class="product-category-list">생활용품 > 헤어/바디/세안</div-->
                        <div class="product-benefit">
                          <ul>
                            <!--li>최대 4% 카드 즉시 할인</li-->
                            <li>최대 {{ commonUtil.numberformat(product.saveMoney) }}원 적립</li>
                          </ul>
                          <button type="button" v-bind:class="{'btn-icon': true, 'btn-zzim-list': true, 'btn-zzim-list-active': product.zzim}" @click="() => zzim(product)">
                            <i class="bi bi-suit-heart-fill"></i> <span class="zzim-text">찜</span>
                          </button>
                        </div>
                    </div>
          </div>
          <div id="sentinel"></div>
          <!-- 데이터 없음 -->
          <!-- End 데이터 없음 -->
        </section>
        <!-- End 최근 본 상품 -->
       </div>
    </div>
  </main>
</template>
<script>
import axios from '@/plugin/axios'
import { reactive, onMounted, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import commonUtil from '@/util/CommonUtil'
export default {
  setup () {
    const API_SERVER = process.env.VUE_APP_API_SERVER
    const store = useStore()
    const route = useRoute()
    /*
    최초 로딩 보정 페이지
    */
    let page = 0
    let keyword = route.params.keyword
    const state = reactive({
      productList: []
    })
    /*
    watch(() => state.keyword, (cur, prev) => {
      console.log(cur)
    })
    */
    watch(route, (cur, pre) => {
      keyword = route.params.keyword
      page = 0
      console.log('page change ' + keyword)
      loadPage('R')
      /*
      loadPage('R')
      console.log('page change ' + keyword)
      */
    })
    onMounted(() => {
      const io = new IntersectionObserver((entries, observer) => {
        if (entries[0].intersectionRatio <= 0) return
        loadPage('R')
      })
      io.observe(document.getElementById('sentinel'))
    })
    const loadPage = async (sortType) => {
      try {
        /*
        로그인 전 상태이면 localstorage에 저장해둔다
        */
        const jwtToken = store.state.jwtToken
        if (!commonUtil.expireCheckJwt(jwtToken) && page === 0) {
          let duple = false
          store.state.keywordList.forEach((e, i) => {
            if (e.keyword === keyword) {
              store.state.keywordList.splice(i, 1)
              duple = true
            }
          })
          if (!duple && store.state.keywordList.length > 9) {
            store.state.keywordList.pop()
          }
          store.commit('setKeywordList', [{ keyword: keyword }, ...store.state.keywordList])
        }

        const params = new URLSearchParams()
        params.append('keyword', keyword)
        params.append('listSize', 32)
        params.append('page', page)
        params.append('sortType', sortType)
        const result = await axios.post(API_SERVER + '/api/public/keywordSearch', params, {
          withCredentials: true, headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        })
        if (page > 0) {
          state.productList = [...state.productList, ...result.data]
        } else {
          state.productList = result.data
        }
        page++
      } catch (e) {
        alert('자료 불러오기에 실패했습니다.')
        console.log(e)
      }
    }
    const zzim = async (product) => {
      if (!commonUtil.expireCheckJwt(store.state.jwtToken)) {
        store.commit('setModalPop', true)
        return
      }
      try {
        const result = await axios.get(API_SERVER + '/api/member/zzim?productIdxnum=' + product.idxnum, {
          withCredentials: true, headers: { 'Content-Type': 'application/json' }
        })
        if (result.data.result === 'PRESENT') {
          alert('이미 찜 등록됐습니다.')
        } else if (result.data.result === 'ADD') {
          product.zzim = true
          alert('찜 등록됐습니다.')
        }
      } catch (e) {
        if (e.response.status === 403) {
          store.commit('setModalPop', true)
        }
      }
    }
    return {
      state, commonUtil, zzim
    }
  }
}
</script>
