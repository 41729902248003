<template>
<div style="margin:20px">
    <pre>
  이 용 약 관

이 약관은 굿즈컴바인(이하 회사)에서 운영하는 쿠알람(www.coualarm.com)에서 제공하는 서비스의 이용 조건 및 절차에 관한 사항과 기타 필요한 사항을 규정함을 목적으로 합니다.
총칙

1. 약관은 회사가 운영하는 웹사이트에서 온라인으로 공시함으로써 효력을 발생합니다.
2. 회사는 사정변경의 경우와 영업상 중요 사유가 있을 때 관련 법령에 위배되지 않는 범위 안에서 약관을 변경할 수 있으며 변경된 약관은 전항과 같은 방법으로 효력이 발생합니다.
3. 이용자의 이용 중 조건의 변경 등에 관한 사항에 대해서 회사는 변경 전이라도 이를 이용자에게 메일, 전화 및 기타의 방법으로 통보함으로써 공지할 수 있으며 이는 약관의 일부로써 유효합니다
4. 변경된 약관의 효력 발생일 이후에도 서비스를 계속 사용할 경우 약관의 변경 사항에 동의한 것으로 간주하며, 변경된 약관에 대한 정보를 알지 못해 발생하는 이용자의 피해는 회사에서 책임지지 않습니다.
5.이 약관에 명시되지 않은 사항이 관계법령에 규정되어 있을 경우에는 그 규정에 따릅니다. 회사의 정액 서비스 이용자 및 기타 아이템 등 유료 서비스 이용자의 경우에는 회사가 별도로 정한 정책에 의거해 권리와 의무를 보장 또는 제한 받을 수 있습니다.

서비스이용
1. 쿠알람(www.coualarm.com)은 타사웹사이트에서 판매되는 제품 가격등의 정보,이벤트정보 및 다른사이트로 연결되는 링크를 제공할 수 있으며, 이용자의 “희망가격설정후 알림받기”를 통해 제품의 가격정보 및 제품구매링크등의 정보를 이메일 또는 SNS 서비스를 이용해 제공할 수 있다.다만 이러한 제품정보,이벤트정보 링크정보등에 대한 보증이나 책임을 부담하지 않습니다.
2.회사는 이용자의 제품 구매등의 활동을 통애 일정의 수수료를 지급받을 수 있습니다..,
3. 쿠알람(www.coualarm.com)은 *수정필요* 별도의 회원 가입 없이 이메일 등록을통해 사용할 수 있다.
4. 다음과 같은 경우에는 사용자의 계정의 사용불가,정지,탈퇴 될 수 있습니다
1,부정한 용도 또는 영리추구를 목적으로 서비스 이용시
2,만 14세 미만의 아동이 서비스 이용사
3.관계 법령과 사회질서 미풍양속을 저해할 목적으로 이용시
4.이외에 회사가 정한 약관이나 규칙에 위배 하거나 부정한 사용, 위법적인 사용이 확인된 경우 회사의 판단에의해 이용제안이 필요시
5. 서비스 이용은 회사의 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴, 1일 24시간을 원칙으로 합니다.
6. 정기점검 등의 필요로 회사가 정한 날 또는 시간대 동안에는 이용시간이 제약 받을 수 있습니다.
7. 이용등록 후에도 불가피한 회사 사정에의해 등록한 정보의 일부 또는 전체를 사용할 수 없을 수 있습니다.
8.회사는 이용자가 등록한 “희망가격성정후 알림받기” 기능을 통해 사용자가 등록한 이메일 또는 사용자가 동의한 방법에 따라 제품정보 및 가격등이 포함된 내용의 메시지를 전달 할 수 있습니다.
개인정보보호
1.회사는 쿠알람(www.coualarm.com)서비스의 원한할 정보제공 및 작동을 위하여 사용자의개인정보를 목적과 범위 내에서만 수집,이용하며 개인정보보호관련 법령에 따라 안전하게 관리합니다.더 자세한 사항은 “개인정보처리방침”에서 확인할 수 있습니다.
서비스이용계약해지

1.이용자는 더 이상 쿠알람(www.coualarm.com)서비스를 원치않을 경우 이용 계약 해지신청과 함께 이용을 중지 할 수 있습니다 .이경우 등록된 알림,찜 정보는 초기화 될수 있으며,재사용이 어려울 수 있씁니다.
2해지신청은 신속하게 처리함을 원칙으로 합니다.다만 이용자와 회사와 분쟁이 있을 경우 해지처리가 지연 될 수 있습니다.
회사의의무
1. 회사는 약관에서 정한 바에 따라 계속적이고 안정적으로 서비스를 제공할 의무가 있습니다.
2. 회사는 이용자로부터 제기되는 의견이나 불만이 정당하다고 인정할 경우에는 즉시 처리하여야 합니다. 단, 즉시 처리가 곤란한 경우에는 이용자에게 그 사유와 처리일정을 통보해야 합니다.
3. 회사는 서비스 제공과 관련하여 알게 된 이용자의 개인 정보를 본인의 승낙 없이 타인에게 누설, 배포하여서는 안되며, 이는 회사가 정하고 공시한 개인정보 보호 정책에 따릅니다.
4. 제3항의 경우 전기통신관계법령 등에 의하여 국가기관의 요구가 있는 경우나, 수사상의 목적으로 영장의 제시가 있는 경우에는 그러하지 아니합니다.
이용자의의무
1. 이용자는 이 약관 및 회사의 공지사항, 서비스별 이용안내 사항을 숙지하고 준수해야 합니다.
2. 이용자는 회사에 제공하는 개인 신상정보가 진실에 기초한 것이어야 하며, 정보가 변경될 때마다 변경된 정보를 수정하여야 합니다. 이용자가 이 의무에 태만하여 발생하는 문제에 대한 책임은 회사가 지지 않습니다.
3. 이용자는 자신의 이용자 EMAIL이나 비밀번호가 부정 사용된 것을 인지하는 즉시 회사에 이 사실을 통보하여야 합니다.
4. 이용자는 회사의 명시적 동의가 없는 한 서비스 이용자로서의 권리 등 서비스 이용계약상의 지위를 타인에게 양도, 증여할 수 없으며 이를 담보로 제공할 수 없습니다.
5. 이용자는 이 약관 및 관계법령에 규정된 제반사항을 준수하여야 합니다. 이를 준수하지 않아 발생하는 모든 문제는 이용자 책임입니다.
서비스책임
회사는 가격정보등 링크를통한 타사이트에서의 물품 등의 거래로 발생하는 분쟁은 책임이 없으며,거래 당사자간의 해결을 원칙으로 하며 회사는 이를 중재할 의무가 없습니다. 회사의 서비스를 이용하여 상업적인 활동으로 인해 회사가 손해를 입는 경우, 해당 이용자는 회사에 대해 손해배상책임을 지며, 회사는 해당 이용자에 대해 서비스의 이용 제한 및 적법한 절차를 통해 손해배상 등을 청구할 수 있습니다.
서비스제공중지
1. 회사는 다음 각 호에 해당하는 경우 서비스의 전부 또는 일부의 제공을 중지할 수 있습니다.
(1)회사의 Server가 설치된 국가 또는 지역의 기간통신 사업자가 전기 통신 서비스를 중지하였거나, 통신사고로 서비스제공이 불가능한 경우.
(2)서비스용 설비의 이전, 보수 또는 공사로 인하여 부득이한 경우. 단, 회사의 계획 아래 중단하는 경우에 회사는 사전에 이용자에게 통보해 주어야 하고 가능한 최소한의 시간 내에 서비스를 재개하도 록 노력해야 합니다.
(3)서비스 설비의 장애 등으로 정상적인 서비스 제공이 어려운 경우. 단, 회사는 가능한 최소한의 시간내에 서비스를 재개하도록 노력해야 합니다.
2. 회사의 장비의노후등의 이유로 유지,보수을위해 정기적,일시적으로 서비스의 일부 또는 전부가 중단 될수있습니다.이러한 경우 공지등을 통해 사전에 알려드리겠습니다.
3.예측할 수 없는 장애로 인한 서비스 중단이 있는경우 , 서비스가 복구 됨과 동시에 상황을 공지등을 통해 알려드리겠습니다.
4.쿠알람 서비스는 무료로 제공되는 서비스로  전부,또는 일부 수정 또는 종료됨경우 관련범령에 특별한 규정이 없는한 별도의 보상은 하지 않습니다.
손해배상

회사는 다음과 같은경우 손해배상 책임을 면할 수 있습니다.
1. 천재지변, 전쟁, 국가 비상 사태 및 기타 이에 준하는 불가항력적인 사유에 의한 경우
2. 기간통신 사업자가 전기 통신 서비스를 중지하거나 정상적으로 제공하지 아니한 경우
3. 이용자의 귀책사유로 인한 경우
4. 사전에 고지한 설비의 보수, 점검 등의 부득이한 경우
5.제3자의 불법적이고 고의적인 서버접속이나 악성프로그램을 통한손해
6. 기타 본 약관이 규정한 사항을 지키지 않은 경우
분쟁조정 및 관할법원
본약관은 회사와 사용자간에 발생한 분쟁등에 관하여는 대한민국법령이 적용되며, 회사 주소지의 관할법원을 관활법원으로 한다.

공고일자 2023년11월30일
시행일자 2023년11월30일
  </pre>
</div>
</template>
<script>
import { onMounted } from 'vue'

export default {
  setup () {
    onMounted(() => {
      window.scrollTo(0, 0)
    })
  }
}
</script>
