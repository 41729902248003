import axios from 'axios'
import commonUtil from '@/util/CommonUtil'

const instance = axios.create({
  headers: {
    'Content-Type': 'application/json'
  }
})
instance.interceptors.request.use(
  config => {
    try {
      const vuexStore = JSON.parse(localStorage.getItem('vuexStore'))
      const token = vuexStore.jwtToken
      const ptnInfo = vuexStore.ptnInfo
      if (token) {
        config.headers.Authorization = 'Bearer ' + token
      }
      if (Object.entries(ptnInfo).length !== 0 && commonUtil.expireCheck(ptnInfo.expire)) {
        config.headers.Ptninfo = 'Bearer ' + ptnInfo.ptn
      }
    } catch (e) {
      // ignore
    }
    return config
  },
  error => {
    Promise.reject(error)
  }
)
export default instance
