<template>
    <main id="main" class="main">
        <div class="main-container">
            <!-- 인기있는 브랜드관 -->
            <rocket-header-view/>
            <section class="mg-t0">
                <div class="section-title mg-b0">
                    <h2 class="title">인기있는 브랜드관</h2>
                </div>
                <div class="tabmenu mg-t10">
                    <ul>
                        <li><a href="javascript:void(0)" v-bind:class="{ 'active': state.appleSelected }" @click="() => changeBrand('Apple')">APPLE제품</a></li>
                        <li><a href="javascript:void(0)" v-bind:class="{ 'active': state.samsungSelected }" @click="() => changeBrand('samsung')">삼성제품</a></li>
                        <li><a href="javascript:void(0)" v-bind:class="{ 'active': state.lgSelected }" @click="() => changeBrand('lg')">LG제품</a></li>
                    </ul>
                </div>
                <div class="swiper-ouside">
                    <swiper class="swiper p1Swiper swiper-wrapper"  :controller="{ control: controlledSwiper }" v-bind="swiperOptions" :modules="modules" @swiper="onSwiper" @slideChange="onSlideChange">
                            <swiper-slide :key="ii" v-for="(product, ii) in state.productList" class="product-item swiper-slide">
                                <div class="product-img">
                                    <router-link v-bind:to="'/detail/'+product.idxnum"><img
                                            v-bind:src="product.preloadImage"
                                            alt="상품이미지" class="thumbnail"></router-link>
                                    <span class="product-badge discount right-top" v-if="product.preDiscount > 1">이전대비 {{ product.preDiscount }}% <img
                                            src="../../assets/images/icon_down.svg" alt="하락"></span>
                                </div>
                                <div class="product-title"><router-link v-bind:to="'/detail/'+product.idxnum">{{product.name}}</router-link></div>
                                <div class="product-price-origin" v-if="product.originPrice > 0"><span class="discount">{{ product.discountPercent }}%</span> <span
                                        class="price-origin">{{ commonUtil.numberformat(product.originPrice) }}원</span></div>
                                <div class="product-price" v-if="product.salePrice > 0 && product.couponPrice == 0"><span class="price">{{ commonUtil.numberformat(product.salePrice) }}</span>원</div>
                                <div class="product-price" v-if="product.couponPrice > 0"><span class="price">{{ commonUtil.numberformat(product.couponPrice) }}</span>원</div>
                                <div class="product-review"><span class="review-score-bg"><span class="review-score"
                                            v-bind:style="{width: product.ratingAverage+'%'}"></span></span> <span class="review-count">({{ commonUtil.numberformat(product.ratingCnt) }})</span>
                                </div>
                                <div class="product-benefit">
                                    <ul>
                                        <!--li>최대 4% 카드 즉시 할인</li-->
                                        <li>최대 {{ product.saveMoney }}원 적립</li>
                                    </ul>
                                    <!--button type="button" v-bind:class="{'btn-icon': true, 'btn-zzim-list': true, 'btn-zzim-list-active': product.zzim}" @click="() => zzim(product)"><i
                                            class="bi bi-suit-heart-fill"></i> <span class="zzim-text">찜</span></button-->
                                </div>
                            </swiper-slide>
                    </swiper>
                    <div class="swiper-button-next swiper-button-next-circle swiper-button-next1"></div>
                    <div class="swiper-button-prev swiper-button-prev-circle swiper-button-prev1"></div>
                    <div class="swiper-pagination swiper-pagination1"></div>
                </div>
            </section>
            <!-- End 인기있는 브랜드관 -->
        </div>

        <hr>

        <div class="main-container">
            <!-- 주간 BEST 20 -->
            <section>
                <div class="section-title">
                    <h2 class="title">쿠알람 주간 인기 구매 <span class="text-red">BEST 20</span></h2>
                </div>
                <div class="swiper-ouside">
                    <swiper class="swiper p2Swiper" v-bind="swiperOptions2" :modules="modules">
                                <swiper-slide :key="ii" v-for="(product, ii) in state.recommList" class="product-item swiper-slide">
                                    <div class="product-img">
                                        <router-link v-bind:to="'/detail/'+product.idxnum"><img
                                                v-bind:src="product.preloadImage"
                                                alt="상품이미지" class="thumbnail"></router-link>
                                        <span class="product-badge discount right-top" v-if="product.preDiscount > 1">이전대비 {{ product.preDiscount }}% <img
                                                src="../../assets/images/icon_down.svg" alt="하락"></span>
                                        <span class="product-badge rank left-top">{{ (ii+1) }}등</span>
                                    </div>
                                    <div class="product-title"><router-link v-bind:to="'/detail/'+product.idxnum">{{product.name}}</router-link></div>
                                    <div class="product-price-origin" v-if="product.originPrice > 0"><span class="discount" v-if="product.discountPercent > 0">{{ product.discountPercent }}%</span> <span
                                        class="price-origin">{{ commonUtil.numberformat(product.originPrice) }}원</span></div>
                                    <div class="product-price" v-if="product.salePrice > 0 && product.couponPrice == 0"><span class="price">{{ commonUtil.numberformat(product.salePrice) }}</span>원</div>
                                    <div class="product-price" v-if="product.couponPrice > 0"><span class="price">{{ commonUtil.numberformat(product.couponPrice) }}</span>원</div>
                                    <div class="product-review"><span class="review-score-bg"><span class="review-score"
                                        v-bind:style="{width: product.ratingAverage+'%'}"></span></span> <span class="review-count">({{ commonUtil.numberformat(product.ratingCnt) }})</span>
                                    </div>
                                    <div class="product-benefit">
                                        <ul>
                                            <!--li>최대 4% 카드 즉시 할인</li-->
                                            <li>최대 {{ product.saveMoney }}원 적립</li>
                                        </ul>
                                        <!--button type="button" v-bind:class="{'btn-icon': true, 'btn-zzim-list': true, 'btn-zzim-list-active': product.zzim}" @click="() => zzim(product)"><i
                                                class="bi bi-suit-heart-fill"></i> <span class="zzim-text">찜</span></button-->
                                    </div>
                                </swiper-slide>
                    </swiper>
                    <div class="swiper-button-next swiper-button-next-circle swiper-button-next2"></div>
                    <div class="swiper-button-prev swiper-button-prev-circle swiper-button-prev2"></div>
                    <div class="swiper-pagination swiper-pagination2"></div>
                </div>
            </section>
            <!-- End 주간 BEST 20 -->
        </div>
        <!-- 카테고리별 상품 -->
        <div class="product-category">
          <div class="swiper-wrapper">
            <swiper class="product-category-container categorySwiper" v-bind="swiperOptions3" :modules="modules">
                    <swiper-slide v-bind:class="{'swiper-slide': true, 'item': true, 'active': state.category['A']}" @click="category('A')">패션의류/잡화</swiper-slide>
                    <swiper-slide v-bind:class="{'swiper-slide': true, 'item': true, 'active': state.category['B']}" @click="category('B')">뷰티</swiper-slide>
                    <swiper-slide v-bind:class="{'swiper-slide': true, 'item': true, 'active': state.category['C']}" @click="category('C')">출산/유아동</swiper-slide>
                    <swiper-slide v-bind:class="{'swiper-slide': true, 'item': true, 'active': state.category['D']}" @click="category('D')">식품</swiper-slide>
                    <swiper-slide v-bind:class="{'swiper-slide': true, 'item': true, 'active': state.category['E']}" @click="category('E')">주방용품</swiper-slide>
                    <swiper-slide v-bind:class="{'swiper-slide': true, 'item': true, 'active': state.category['F']}" @click="category('F')">생활용품</swiper-slide>
                    <swiper-slide v-bind:class="{'swiper-slide': true, 'item': true, 'active': state.category['G']}" @click="category('G')">홈인테리어</swiper-slide>
                    <swiper-slide v-bind:class="{'swiper-slide': true, 'item': true, 'active': state.category['H']}" @click="category('H')">가전디지털</swiper-slide>
                    <swiper-slide v-bind:class="{'swiper-slide': true, 'item': true, 'active': state.category['I']}" @click="category('I')">스포츠/레저</swiper-slide>
                    <swiper-slide v-bind:class="{'swiper-slide': true, 'item': true, 'active': state.category['J']}" @click="category('J')">자동차용품</swiper-slide>
                    <swiper-slide v-bind:class="{'swiper-slide': true, 'item': true, 'active': state.category['K']}" @click="category('K')">도서/음반/DVD</swiper-slide>
                    <swiper-slide v-bind:class="{'swiper-slide': true, 'item': true, 'active': state.category['L']}" @click="category('L')">완구/취미</swiper-slide>
                    <swiper-slide v-bind:class="{'swiper-slide': true, 'item': true, 'active': state.category['M']}" @click="category('M')">문구/오피스</swiper-slide>
                    <swiper-slide v-bind:class="{'swiper-slide': true, 'item': true, 'active': state.category['N']}" @click="category('N')">헬스/건강식품</swiper-slide>
                    <swiper-slide v-bind:class="{'swiper-slide': true, 'item': true, 'active': state.category['O']}" @click="category('O')">반려동물용품</swiper-slide>
                    <swiper-slide v-bind:class="{'swiper-slide': true, 'item': true, 'active': state.category['P']}" @click="category('P')">테마관</swiper-slide>
                    <swiper-slide v-bind:class="{'swiper-slide': true, 'item': true, 'active': state.category['Q']}" @click="category('Q')">쿠팡수입</swiper-slide>
                  </swiper>
                <div class="swiper-button-next swiper-button-next-category swiper-button-next3"></div>
                <div class="swiper-button-prev swiper-button-prev-category swiper-button-prev3"></div>
           </div>
        </div>
        <div class="main-container">
            <section class="mg-t0">
                <div class="tabmenu-wrap">
                    <div class="tabmenu">
                        <ul>
                            <li><a href="#" class="active">추천순</a></li>
                            <li><a href="#" class="">할인률순</a></li>
                        </ul>
                    </div>
                    <div>
                        <!--label for="soldOut"><input type="checkbox" id="soldOut"> 품절제외</label-->
                    </div>
                </div>
                <div class="product-list"> <!-- .product-list-6 클래스 추가 시 6개 노출 -->
                    <div class="product-item" :key="ii" v-for="(product, ii) in state.mainList">
                        <div class="product-img">
                            <router-link v-bind:to="'/detail/'+product.idxnum"><img
                                    v-bind:src="product.preloadImage"
                                    alt="상품이미지" class="thumbnail"></router-link>
                            <span class="product-badge discount left-top" v-if="product.preDiscount > 3">{{ product.preDiscount }}% <img src="../../assets/images/icon_down.svg"
                                    alt="하락"></span>
                            <!--span class="product-badge slodout right-top">품절임박</span-->
                        </div>
                        <div class="product-title"><router-link v-bind:to="'/detail/'+product.idxnum">{{ product.name }}</router-link></div>
                        <div class="product-price-origin" v-if="product.originPrice > 0"><span class="discount" v-if="product.discountPercent > 0">{{ product.discountPercent }}%</span> <span
                                        class="price-origin">{{ commonUtil.numberformat(product.originPrice) }}원</span></div>
                        <div class="product-price" v-if="product.salePrice > 0 && product.couponPrice == 0"><span class="price">{{ commonUtil.numberformat(product.salePrice) }}</span>원</div>
                        <div class="product-price" v-if="product.couponPrice > 0"><span class="price">{{ commonUtil.numberformat(product.couponPrice) }}</span>원</div>
                        <div class="product-review"><span class="review-score-bg"><span class="review-score"
                                        v-bind:style="{width: product.ratingAverage+'%'}"></span></span> <span class="review-count">({{ commonUtil.numberformat(product.ratingCnt) }})</span></div>
                        <!--div class="product-price-change">450원 하락, 하락추세, 3일간 하락률 3%</div>
                        <div class="product-category-list">생활용품 > 헤어/바디/세안</div-->
                        <div class="product-benefit">
                          <ul>
                            <!--li>최대 4% 카드 즉시 할인</li-->
                            <li>최대 {{ commonUtil.numberformat(product.saveMoney) }}원 적립</li>
                          </ul>
                          <button type="button" v-bind:class="{'btn-icon': true, 'btn-zzim-list': true, 'btn-zzim-list-active': product.zzim}" @click="() => zzim(product)">
                            <i class="bi bi-suit-heart-fill"></i> <span class="zzim-text">찜</span>
                          </button>
                        </div>
                    </div>
                </div>
                <div class="mg-t40 text-center">
                  <router-link :to="'/list/' + state.currentCategory" class="btn btn-lg btn-primary">상품 더보기</router-link>
                </div>
                <!--nav class="pagination-container">
                    <ul class="pagination">
                        <li class="page-item disabled">
                            <a class="page-link" href="#">
                                <span><i class="bi bi-caret-left-fill"></i></span>
                            </a>
                        </li>
                        <li class="page-item active"><a class="page-link" href="#">1</a></li>
                        <li class="page-item"><a class="page-link" href="#">2</a></li>
                        <li class="page-item"><a class="page-link" href="#">3</a></li>
                        <li class="page-item">
                            <a class="page-link" href="#">
                                <span><i class="bi bi-caret-right-fill"></i></span>
                            </a>
                        </li>
                    </ul>
                </nav-->
            </section>
        </div>
        <!-- End 카테고리별 상품 -->
    </main>
</template>
<script>
import axios from '@/plugin/axios'
import { onMounted, reactive, ref } from 'vue'
import { useStore } from 'vuex'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import { Controller, Navigation, Pagination, Mousewheel, Keyboard } from 'swiper/modules'
import commonUtil from '@/util/CommonUtil'
import RocketHeaderView from './RocketHeaderView.vue'
export default {
  name: 'MainView',
  components: {
    Swiper,
    SwiperSlide,
    RocketHeaderView
  },
  setup () {
    const API_SERVER = process.env.VUE_APP_API_SERVER
    const store = useStore()
    let totalProductList = []
    const cTemplate = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O']
    const state = reactive({
      productList: [],
      recommList: [],
      mainList: [],
      appleSelected: true,
      samsungSelected: false,
      lgSelected: false,
      currentCategory: 'H',
      category: { A: false, B: false, C: false, D: false, E: false, F: false, G: false, H: false, I: false, J: false, K: false, L: false, M: false, N: false, O: false }
    })
    const swiperOptions = {
      slidesPerView: 4,
      spaceBetween: 30,
      mousewheel: true,
      pagination: { clickable: false, el: '.swiper-pagination1' },
      breakpoints: { 10: { slidesPerView: 1 }, 300: { slidesPerView: 2 }, 760: { slidesPerView: 4 } },
      keyboard: true,
      navigation: {
        nextEl: '.swiper-button-next1',
        prevEl: '.swiper-button-prev1'
      }
    }
    const swiperOptions2 = {
      slidesPerView: 4,
      spaceBetween: 30,
      mousewheel: true,
      pagination: { clickable: false, el: '.swiper-pagination2' },
      breakpoints: { 10: { slidesPerView: 1 }, 300: { slidesPerView: 2 }, 760: { slidesPerView: 4 } },
      keyboard: true,
      navigation: {
        nextEl: '.swiper-button-next2',
        prevEl: '.swiper-button-prev2'
      }
    }
    const swiperOptions3 = {
      slidesPerView: 'auto',
      spaceBetween: 0,
      mousewheel: true,
      keyboard: true,
      navigation: {
        nextEl: '.swiper-button-next3',
        prevEl: '.swiper-button-prev3'
      }
    }
    onMounted(async () => {
      if (commonUtil.expireCheck(store.state.mainListTimestamp) && Object.entries(store.state.mainList).length !== 0) {
        totalProductList = store.state.mainList
        state.productList = totalProductList.Apple
        state.recommList = totalProductList.recommend
        /* state.mainList = totalProductList.mainList */
        /* return */
      } else {
        try {
          const result = await axios.get(API_SERVER + '/api/public/mainProductList?cate=' + state.currentCategory, {
            withCredentials: true, headers: { 'Content-Type': 'application/json' }
          })
          totalProductList = result.data
          state.productList = totalProductList.Apple
          state.recommList = totalProductList.recommend
          /* state.mainList = totalProductList.mainList */
          store.commit('setMainList', totalProductList)
          store.commit('setMainListTimestamp', commonUtil.expireGenerate(30))
        } catch (e) {
          alert('자료 불러오기에 실패했습니다.')
          console.log(e)
        }
      }
      category(cTemplate[Math.floor(Math.random() * cTemplate.length)])
    })
    const controlledSwiper = ref(null)
    const onSwiper = (swiper) => {
      /*
      console.log(swiper)
      */
      controlledSwiper.value = swiper
    }
    const onSlideChange = () => {
      /*
      console.log('slide change')
      */
    }
    const changeBrand = (brand) => {
      if (brand === 'Apple' && state.appleSelected) return
      if (brand === 'samsung' && state.samsungSelected) return
      if (brand === 'lg' && state.lgSelected) return
      if (brand === 'Apple') {
        state.productList = totalProductList.Apple
        state.appleSelected = true
        state.samsungSelected = false
        state.lgSelected = false
      } else if (brand === 'samsung') {
        state.productList = totalProductList.samsung
        state.appleSelected = false
        state.samsungSelected = true
        state.lgSelected = false
      } else if (brand === 'lg') {
        state.productList = totalProductList.lg
        state.appleSelected = false
        state.samsungSelected = false
        state.lgSelected = true
      }
      controlledSwiper.value.slideTo(0, 1000)
    }
    const category = async (cate) => {
      cTemplate.forEach(v => {
        state.category[v] = false
      })
      state.category[cate] = true
      state.currentCategory = cate

      try {
        const result = await axios.get(API_SERVER + '/api/public/categoryProductList?category=' + cate + '&listSize=12&page=0&sortType=R', {
          withCredentials: true, headers: { 'Content-Type': 'application/json' }
        })
        state.mainList = result.data
      } catch (e) {
        alert('자료 불러오기에 실패했습니다.')
        console.log(e)
      }
    }

    const zzim = async (product) => {
      if (!commonUtil.expireCheckJwt(store.state.jwtToken)) {
        store.commit('setModalPop', true)
        return
      }
      try {
        const result = await axios.get(API_SERVER + '/api/member/zzim?productIdxnum=' + product.idxnum, {
          withCredentials: true, headers: { 'Content-Type': 'application/json' }
        })
        if (result.data.result === 'PRESENT') {
          alert('이미 찜 등록됐습니다.')
        } else if (result.data.result === 'ADD') {
          product.zzim = true
          alert('찜 등록됐습니다.')
        }
      } catch (e) {
        if (e.response.status === 403) {
          store.commit('setModalPop', true)
        }
      }
    }

    return {
      modules: [Controller, Navigation, Pagination, Mousewheel, Keyboard], controlledSwiper, onSwiper, onSlideChange, swiperOptions, swiperOptions2, swiperOptions3, state, changeBrand, commonUtil, category, zzim
    }
  }
}
</script>
<style scoped>
.swiper-button-next-circle {
  margin-top: 0px;
  position: absolute;
  top: 50%;
  right: -30px;
  width: 45px;
  height: 45px;
  transform: translateY(-50%);
}
.swiper-button-prev-circle {
  position: absolute;
  top: 50%;
  left: -30px;
  width: 45px;
  height: 45px;
  transform: translateY(-50%);
  margin-top: 0px;
}
.swiper-button-next-circle,
.swiper-button-prev-circle {
border: 1px solid #e9e9e9;
border-radius: 50%;
background: #fff;
box-shadow: 0px 3px 2px rgba(0,0,0,.1);
}
.swiper-button-next-circle::after,
.swiper-button-prev-circle::after {
  font-size: 24px;
}
.swiper-button-next.swiper-button-disabled, .swiper-button-prev.swiper-button-disabled {
  opacity: 0;
}

.swiper-button-next-category,
.swiper-button-prev-category {
  color: var(--color-white);
}
.swiper-button-next-category::after,
.swiper-button-prev-category::after {
  font-size: 20px;
}
.swiper-button-next-category {
  right: 0;
  background-image: linear-gradient(to left,var(--color-primary) 0,rgba(9,198,101,0) 100%);
}
.swiper-button-prev-category {
  left: 0;
  background-image: linear-gradient(to right,var(--color-primary) 0,rgba(9,198,101,0) 100%);
}

@media screen and (max-width: 768px) {
  .swiper-button-next-circle, .swiper-button-prev-circle {
    display: none;
  }
}
</style>
