import { createStore } from 'vuex'
import { createVuexPersistedState } from 'vue-persistedstate'

function parseToken (token) {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
  }).join(''))
  return JSON.parse(jsonPayload)
}
// state, getters, mutations, actions, modules
export default createStore({
  plugins: [createVuexPersistedState({
    whiteList: ['jwtToken', 'mainList', 'keywordList', 'refreshToken', 'mainListTimestamp', 'ptnInfo'],
    key: 'vuexStore',
    storage: window.localStorage
  })],
  state: {
    counter: 2,
    modalPop: false,
    priceAlarmModalPop: false,
    jwtToken: '',
    refreshToken: '',
    mainList: {},
    keywordList: [],
    mainListTimestamp: 0,
    naverState: '',
    myInfoModalPop: false,
    ptnInfo: {}
  },
  getters: {
    getTwoPowerCounter (state) {
      return state.counter ** 2
    },
    getLoginStatus (state) {
      const currentNow = Number(new Date().getTime())
      if (!state.jwtToken) return false
      if (Number(parseToken(state.jwtToken).exp + '000') > currentNow) return true
      if (Number(parseToken(state.refreshToken).exp + '000') < currentNow) return false
    }
  },
  mutations: {
    setCounter (state, value) {
      state.counter = value
    },
    setModalPop (state, value) {
      state.modalPop = value
    },
    setMyInfoModalPop (state, value) {
      state.myInfoModalPop = value
    },
    setPriceAlarmModalPop (state, value) {
      state.priceAlarmModalPop = value
    },
    setJwtToken (state, value) {
      state.jwtToken = value
    },
    setRefreshToken (state, value) {
      state.refreshToken = value
    },
    setMainList (state, value) {
      state.mainList = value
    },
    setKeywordList (state, value) {
      state.keywordList = value
    },
    setMainListTimestamp (state, value) {
      state.mainListTimestamp = value
    },
    setNaverState (state, value) {
      state.naverState = value
    },
    setPtnInfo (state, value) {
      state.ptnInfo = value
    }
  }
})
