<template>
  <aside class="aside-category">
        <div class="category-aside">
          <ul style="display:none;margin-left:20px" id="side-subcategory">
              <li v-for="(subCate , index) in state.subCategoryList" :key="index" :class="{ 'category-active': subCate.active }" @click="() => cateClick(subCate.code)">{{ subCate.name }}</li>
          </ul>
          <ul id="side-category">
            <li id="side-cateA" @click="() => cateClick('A')" :class="{ 'category-active': state.categoryActive.A }">패션의류/잡화</li>
            <li id="side-cateB" @click="() => cateClick('B')" :class="{ 'category-active': state.categoryActive.B }">뷰티</li>
            <li id="side-cateC" @click="() => cateClick('C')" :class="{ 'category-active': state.categoryActive.C }">출산/유아동</li>
            <li id="side-cateD" @click="() => cateClick('D')" :class="{ 'category-active': state.categoryActive.D }">식품</li>
            <li id="side-cateE" @click="() => cateClick('E')" :class="{ 'category-active': state.categoryActive.E }">주방용품</li>
            <li id="side-cateF" @click="() => cateClick('F')" :class="{ 'category-active': state.categoryActive.F }">생활용품</li>
            <li id="side-cateG" @click="() => cateClick('G')" :class="{ 'category-active': state.categoryActive.G }">홈인테리어</li>
            <li id="side-cateH" @click="() => cateClick('H')" :class="{ 'category-active': state.categoryActive.H }">가전디지털</li>
            <li id="side-cateI" @click="() => cateClick('I')" :class="{ 'category-active': state.categoryActive.I }">스포츠/레저</li>
            <li id="side-cateJ" @click="() => cateClick('J')" :class="{ 'category-active': state.categoryActive.J }">자동차용품</li>
            <li id="side-cateK" @click="() => cateClick('K')" :class="{ 'category-active': state.categoryActive.K }">도서/음반/DVD</li>
            <li id="side-cateL" @click="() => cateClick('L')" :class="{ 'category-active': state.categoryActive.L }">완구/취미</li>
            <li id="side-cateM" @click="() => cateClick('M')" :class="{ 'category-active': state.categoryActive.M }">문구/오피스</li>
            <li id="side-cateN" @click="() => cateClick('N')" :class="{ 'category-active': state.categoryActive.N }">헬스/건강식품</li>
            <li id="side-cateO" @click="() => cateClick('O')" :class="{ 'category-active': state.categoryActive.O }">반려동물용품</li>
            <li id="side-cateP" @click="() => cateClick('P')" :class="{ 'category-active': state.categoryActive.P }">테마관</li>
            <li id="side-cateQ" @click="() => cateClick('Q')" :class="{ 'category-active': state.categoryActive.Q }">쿠팡수입</li>
          </ul>
          <ul id="side-category-mobile">
            <li id="side-cateA" @click="() => cateClick('A')" :class="{ 'category-active': state.categoryActive.A }">패션의류/잡화</li>
            <li id="side-cateB" @click="() => cateClick('B')" :class="{ 'category-active': state.categoryActive.B }">뷰티</li>
            <li id="side-cateC" @click="() => cateClick('C')" :class="{ 'category-active': state.categoryActive.C }">출산/유아동</li>
            <li id="side-cateD" @click="() => cateClick('D')" :class="{ 'category-active': state.categoryActive.D }">식품</li>
            <li id="side-cateE" @click="() => cateClick('E')" :class="{ 'category-active': state.categoryActive.E }">주방용품</li>
            <li id="side-cateF" @click="() => cateClick('F')" :class="{ 'category-active': state.categoryActive.F }">생활용품</li>
            <li id="side-cateG" @click="() => cateClick('G')" :class="{ 'category-active': state.categoryActive.G }">홈인테리어</li>
            <li id="side-cateH" @click="() => cateClick('H')" :class="{ 'category-active': state.categoryActive.H }">가전디지털</li>
            <li id="side-cateI" @click="() => cateClick('I')" :class="{ 'category-active': state.categoryActive.I }">스포츠/레저</li>
            <li id="side-cateJ" @click="() => cateClick('J')" :class="{ 'category-active': state.categoryActive.J }">자동차용품</li>
            <li id="side-cateK" @click="() => cateClick('K')" :class="{ 'category-active': state.categoryActive.K }">도서/음반/DVD</li>
            <li id="side-cateL" @click="() => cateClick('L')" :class="{ 'category-active': state.categoryActive.L }">완구/취미</li>
            <li id="side-cateM" @click="() => cateClick('M')" :class="{ 'category-active': state.categoryActive.M }">문구/오피스</li>
            <li id="side-cateN" @click="() => cateClick('N')" :class="{ 'category-active': state.categoryActive.N }">헬스/건강식품</li>
            <li id="side-cateO" @click="() => cateClick('O')" :class="{ 'category-active': state.categoryActive.O }">반려동물용품</li>
            <li id="side-cateP" @click="() => cateClick('P')" :class="{ 'category-active': state.categoryActive.P }">테마관</li>
            <li id="side-cateQ" @click="() => cateClick('Q')" :class="{ 'category-active': state.categoryActive.Q }">쿠팡수입</li>
          </ul>
        </div>
  </aside>
</template>
<script>
import { onMounted, reactive } from 'vue'
export default {
  props: {
    choiceCategory: { type: String, required: true }
  },
  setup (props, context) {
    const cateListA = [{ name: '여성패션', code: 'A01' }, { name: '남성패션', code: 'A02' }, { name: '남녀 공용 의류', code: 'A03' }, { name: '유아동패션', code: 'A04' }]
    const cateListB = [{ name: '로켓럭셔리', code: 'B01' }, { name: '스킨케어', code: 'B02' }, { name: '클린/비건뷰티', code: 'B03' }, { name: '클렌징/필링', code: 'B04' }, { name: '더마코스메틱', code: 'B05' }, { name: '메이크업', code: 'B06' }, { name: '향수', code: 'B07' }, { name: '남성화장품', code: 'B08' }, { name: '네일', code: 'B09' }, { name: '뷰티소품', code: 'B10' }, { name: '어린이화장품', code: 'B11' }, { name: '로드샵', code: 'B12' }, { name: '헤어', code: 'B13' }, { name: '바디', code: 'B14' }, { name: '선물세트/키트', code: 'B15' }]
    const cateListC = [{ name: '유아동패션', code: 'C01' }, { name: '기저귀', code: 'C02' }, { name: '물티슈', code: 'C03' }, { name: '분유/어린이식품', code: 'C04' }, { name: '어린이 건강식품', code: 'C05' }, { name: '수유용품', code: 'C06' }, { name: '이유용품/유아식기', code: 'C07' }, { name: '매트/안전용품', code: 'C08' }, { name: '유모차/웨건', code: 'C09' }, { name: '카시트', code: 'C10' }, { name: '아기띠/외출용품', code: 'C11' }, { name: '욕실용품/스킨케어', code: 'C12' }, { name: '위생/건강/세제', code: 'C13' }, { name: '유아동침구', code: 'C14' }, { name: '유아가구/인테리어', code: 'C15' }, { name: '완구/교구', code: 'C16' }, { name: '유아동도서', code: 'C17' }, { name: '출산준비물/선물', code: 'C18' }, { name: '임부/태교용품', code: 'C19' }]
    const cateListD = [{ name: '유기농/친환경 전문관', code: 'D01' }, { name: '과일', code: 'D02' }, { name: '견과/건과', code: 'D03' }, { name: '채소', code: 'D04' }, { name: '쌀/잡곡', code: 'D05' }, { name: '축산/계란', code: 'D06' }, { name: '수산물/건어물', code: 'D07' }, { name: '생수/음료', code: 'D08' }, { name: '커피/원두/차', code: 'D09' }, { name: '과자/초콜릿/시리얼', code: 'D10' }, { name: '면/통조림/가공식품', code: 'D11' }, { name: '가루/조미료/오일', code: 'D12' }, { name: '장/소스/드레싱/식초', code: 'D13' }, { name: '유제품/아이스크림', code: 'D14' }, { name: '냉장/냉동/간편요리', code: 'D15' }, { name: '건강식품', code: 'D16' }, { name: '분유/어린이식품', code: 'D17' }, { name: '선물세트관', code: 'D18' }, { name: '수입식품관', code: 'D19' }, { name: '반찬/간편식/대용식', code: 'D20' }, { name: '곤약/방탄커피 외', code: 'D21' }, { name: '창고형매장', code: 'D22' }]
    const cateListE = [{ name: '주방가전', code: 'E01' }, { name: '냄비/프라이팬', code: 'E02' }, { name: '주방조리도구', code: 'E03' }, { name: '그릇/홈세트', code: 'E04' }, { name: '수저/커트러리', code: 'E05' }, { name: '컵/텀블러/와인용품', code: 'E06' }, { name: '주전자/커피/티용품', code: 'E07' }, { name: '주방수납/정리', code: 'E08' }, { name: '밀폐저장/도시락', code: 'E09' }, { name: '주방잡화', code: 'E10' }, { name: '일회용품/종이컵', code: 'E11' }, { name: '보온/보냉용품', code: 'E12' }, { name: '수입주방', code: 'E13' }, { name: '1인가구 주방용품', code: 'E14' }]
    const cateListF = [{ name: '방한용품', code: 'F01' }, { name: '헤어', code: 'F02' }, { name: '바디/세안', code: 'F03' }, { name: '구강/면도', code: 'F04' }, { name: '화장지/물티슈', code: 'F05' }, { name: '생리대/성인기저귀', code: 'F06' }, { name: '기저귀', code: 'F07' }, { name: '세탁세제', code: 'F08' }, { name: '청소/주방세제', code: 'F09' }, { name: '탈취/방향/살충', code: 'F10' }, { name: '건강/의료용품', code: 'F11' }, { name: '성인용품(19)', code: 'F12' }, { name: '세탁/청소용품', code: 'F13' }, { name: '욕실용품', code: 'F14' }, { name: '생활전기용품', code: 'F15' }, { name: '수납/정리', code: 'F16' }, { name: '주방수납/잡화', code: 'F17' }, { name: '생활잡화', code: 'F18' }, { name: '공구/철물/DIY', code: 'F19' }, { name: '안전/호신용품', code: 'F20' }, { name: '쿠팡Only', code: 'F21' }]
    const cateListG = [{ name: '방한용품', code: 'G01' }, { name: 'F/W 침구샵', code: 'G02' }, { name: '싱글하우스', code: 'G03' }, { name: '홈데코', code: 'G04' }, { name: '가구', code: 'G05' }, { name: '수납/정리', code: 'G06' }, { name: '침구', code: 'G07' }, { name: '커튼/블라인드', code: 'G08' }, { name: '카페트/쿠션/거실화', code: 'G09' }, { name: '수예/수선', code: 'G10' }, { name: '욕실용품', code: 'G11' }, { name: '조명/스탠드', code: 'G12' }, { name: '셀프인테리어', code: 'G13' }, { name: '원예/가드닝', code: 'G14' }, { name: '생활전기용품', code: 'G15' }, { name: '공구/철물/DIY', code: 'G16' }, { name: '안전/호신용품', code: 'G17' }, { name: '세탁/청소용품', code: 'G18' }, { name: '크리스마스용품', code: 'G19' }]
    const cateListH = [{ name: 'TV/영상가전', code: 'H01' }, { name: '냉장고', code: 'H02' }, { name: '세탁기/건조기', code: 'H03' }, { name: '생활가전', code: 'H04' }, { name: '청소기', code: 'H05' }, { name: '계절가전', code: 'H06' }, { name: '뷰티/헤어가전', code: 'H07' }, { name: '건강가전', code: 'H08' }, { name: '주방가전', code: 'H09' }, { name: '노트북', code: 'H10' }, { name: '데스크탑', code: 'H11' }, { name: '모니터', code: 'H12' }, { name: '휴대폰', code: 'H13' }, { name: '태블릿PC', code: 'H14' }, { name: '스마트워치/밴드', code: 'H15' }, { name: '게임', code: 'H16' }, { name: '키보드/마우스', code: 'H17' }, { name: '저장장치', code: 'H18' }, { name: '프린터/복합기', code: 'H19' }, { name: 'PC부품', code: 'H20' }, { name: 'PC주변기기', code: 'H21' }, { name: '음향기기', code: 'H22' }, { name: '카메라', code: 'H23' }, { name: '전동킥보드/자전거', code: 'H24' }, { name: '차량용 디지털', code: 'H25' }, { name: '1인방송 전문관', code: 'H26' }, { name: '휴대폰 액세서리', code: 'H27' }]
    const cateListI = [{ name: '캠핑전문관', code: 'I01' }, { name: '홈트레이닝', code: 'I02' }, { name: '수영/수상스포츠', code: 'I03' }, { name: '골프', code: 'I04' }, { name: '자전거', code: 'I05' }, { name: '킥보드/스케이트', code: 'I06' }, { name: '낚시', code: 'I07' }, { name: '등산/아웃도어', code: 'I08' }, { name: '스포츠신발', code: 'I09' }, { name: '남성스포츠의류', code: 'I10' }, { name: '여성스포츠의류', code: 'I11' }, { name: '유아스포츠의류', code: 'I12' }, { name: '스포츠잡화', code: 'I13' }, { name: '구기스포츠', code: 'I14' }, { name: '라켓스포츠', code: 'I15' }, { name: '헬스/요가/댄스', code: 'I16' }, { name: '복싱/검도/태권도', code: 'I17' }, { name: '기타스포츠', code: 'I18' }, { name: '스키/겨울스포츠', code: 'I19' }]
    const cateListJ = [{ name: '인테리어', code: 'J01' }, { name: '익스테리어', code: 'J02' }, { name: '세차/카케어', code: 'J03' }, { name: '차량용 전자기기', code: 'J04' }, { name: '차량관리/소모품', code: 'J05' }, { name: 'RV/아웃도어', code: 'J06' }, { name: '부품/안전/공구', code: 'J07' }, { name: '오토바이/전동킥보드', code: 'J08' }, { name: '로켓설치 타이어', code: 'J09' }, { name: '겨울철 차량관리', code: 'J10' }]
    const cateListK = [{ name: '유아/어린이', code: 'K01' }, { name: '소설/에세이/시', code: 'K02' }, { name: '초중고참고서', code: 'K03' }, { name: '가정 살림', code: 'K04' }, { name: '건강 취미', code: 'K05' }, { name: '경제 경영', code: 'K06' }, { name: '과학/공학', code: 'K07' }, { name: '국어/외국어/사전', code: 'K08' }, { name: '대학교재', code: 'K09' }, { name: '만화/라이트노벨', code: 'K10' }, { name: '사회 정치', code: 'K11' }, { name: '수험서/자격증', code: 'K12' }, { name: '여행', code: 'K13' }, { name: '역사', code: 'K14' }, { name: '예술', code: 'K15' }, { name: '인문', code: 'K16' }, { name: '자기계발', code: 'K17' }, { name: '잡지', code: 'K18' }, { name: '종교', code: 'K19' }, { name: '청소년', code: 'K20' }, { name: '해외도서', code: 'K21' }, { name: 'IT컴퓨터', code: 'K22' }, { name: 'CD/LP', code: 'K23' }, { name: 'DVD/블루레이', code: 'K24' }]
    const cateListL = [{ name: '캐릭터별완구', code: 'L01' }, { name: '신생아/영아완구', code: 'L02' }, { name: '로봇/작동완구', code: 'L03' }, { name: '역할놀이', code: 'L04' }, { name: '블록놀이', code: 'L05' }, { name: '인형', code: 'L06' }, { name: '물놀이/계절완구', code: 'L07' }, { name: '실내대형완구', code: 'L08' }, { name: 'STEAM완구', code: 'L09' }, { name: '학습완구/교구', code: 'L10' }, { name: '보드게임', code: 'L11' }, { name: 'RC완구/부품', code: 'L12' }, { name: '퍼즐/큐브/피젯토이', code: 'L13' }, { name: '프라모델', code: 'L14' }, { name: '피규어/다이캐스트', code: 'L15' }, { name: '콘솔/휴대용게임기', code: 'L16' }, { name: '파티/마술용품', code: 'L17' }, { name: 'DIY', code: 'L18' }, { name: '악기/음향기기', code: 'L19' }, { name: '원예/가드닝', code: 'L20' }, { name: '수집품', code: 'L21' }, { name: '연령별완구', code: 'L22' }, { name: '키덜트', code: 'L23' }, { name: '승용완구', code: 'L24' }, { name: '스포츠/야외완구', code: 'L25' }]
    const cateListM = [{ name: '사무용품 전문관', code: 'M01' }, { name: '미술/화방용품', code: 'M02' }, { name: '캐릭터 문구', code: 'M03' }, { name: '학용품/수업준비', code: 'M04' }, { name: '필기구', code: 'M05' }, { name: '노트/메모지', code: 'M06' }, { name: '다이어리/플래너', code: 'M07' }, { name: '바인더/파일', code: 'M08' }, { name: '파티/이벤트', code: 'M09' }, { name: '데코/포장용품', code: 'M10' }, { name: '카드/엽서/봉투', code: 'M11' }, { name: '앨범', code: 'M12' }, { name: '복사용품/라벨지', code: 'M13' }, { name: '보드/칠판/광고', code: 'M14' }]
    const cateListN = [{ name: '건강기능식품', code: 'N01' }, { name: '성인용 건강식품', code: 'N02' }, { name: '여성용 건강식품', code: 'N03' }, { name: '남성용 건강식품', code: 'N04' }, { name: '임산부 건강식품', code: 'N05' }, { name: '시니어 건강식품', code: 'N06' }, { name: '어린이 건강식품', code: 'N07' }, { name: '비타민/미네랄', code: 'N08' }, { name: '영양제', code: 'N09' }, { name: '허브/식물추출물', code: 'N10' }, { name: '홍삼/인삼', code: 'N11' }, { name: '건강즙/음료', code: 'N12' }, { name: '꿀/프로폴리스', code: 'N13' }, { name: '건강분말/건강환', code: 'N14' }, { name: '헬스보충식품', code: 'N15' }, { name: '다이어트/이너뷰티', code: 'N16' }, { name: '홈트레이닝', code: 'N17' }, { name: '헬스/요가용품', code: 'N18' }, { name: '건강가전', code: 'N19' }, { name: '건강도서', code: 'N20' }, { name: '건강/의료용품', code: 'N21' }, { name: '곤약/방탄커피 외', code: 'N22' }, { name: '샐러드/닭가슴살', code: 'N23' }]
    const cateListO = [{ name: '강아지 사료', code: 'O01' }, { name: '강아지 간식', code: 'O02' }, { name: '강아지 영양제', code: 'O03' }, { name: '강아지 용품', code: 'O04' }, { name: '고양이 사료', code: 'O05' }, { name: '고양이 간식', code: 'O06' }, { name: '고양이 영양제', code: 'O07' }, { name: '고양이 용품', code: 'O08' }, { name: '펫티켓 산책용품', code: 'O09' }, { name: '관상어 용품', code: 'O10' }, { name: '소동물/가축용품', code: 'O11' }]
    const cateListP = [{ name: '로켓럭셔리', code: 'P01' }, { name: '로켓설치', code: 'P02' }, { name: '공간별 집꾸미기', code: 'P03' }, { name: '헬스케어 전문관', code: 'P04' }, { name: '쿠팡 Only', code: 'P05' }, { name: '싱글라이프', code: 'P06' }, { name: '악기전문관', code: 'P07' }, { name: '결혼준비', code: 'P08' }, { name: '아트/공예', code: 'P09' }, { name: '미세먼지용품', code: 'P10' }, { name: '홈카페', code: 'P11' }, { name: '실버스토어', code: 'P12' }, { name: '로켓펫닥터', code: 'P13' }]
    const cateListQ = [{ name: '신선식품', code: 'Q01' }, { name: '식품', code: 'Q02' }, { name: '가전디지털', code: 'Q03' }, { name: '홈인테리어', code: 'Q04' }, { name: '주방용품', code: 'Q05' }, { name: '생활용품', code: 'Q06' }, { name: '헬스/건강식품', code: 'Q07' }, { name: '스포츠/레저', code: 'Q08' }, { name: '완구/취미', code: 'Q09' }, { name: '반려동물용품', code: 'Q10' }, { name: '자동차용품', code: 'Q11' }, { name: '패션', code: 'Q12' }, { name: '뷰티', code: 'Q13' }, { name: '문구/오피스', code: 'Q14' }, { name: '출산/유아동', code: 'Q15' }, { name: '도서/음반/DVD', code: 'Q16' }]
    const categoryMap = { A: cateListA, B: cateListB, C: cateListC, D: cateListD, E: cateListE, F: cateListF, G: cateListG, H: cateListH, I: cateListI, J: cateListJ, K: cateListK, L: cateListL, M: cateListM, N: cateListN, O: cateListO, P: cateListP, Q: cateListQ }
    const categoryActive = { A: false, B: false, C: false, D: false, E: false, F: false, G: false, H: false, I: false, J: false, K: false, L: false, M: false, N: false, O: false, P: false, Q: false }
    const { emit } = context
    const state = reactive({
      choiceCategory: '', subCategoryList: [], categoryActive: categoryActive
    })
    onMounted(() => {
      cateClick(props.choiceCategory)
    })
    const cateClick = (cate) => {
      if (cate === state.choiceCategory) return
      if (state.choiceCategory !== '') resetCate(cate)
      state.subCategoryList = categoryMap[cate.substring(0, 1)]
      state.categoryActive[cate.substring(0, 1)] = true
      document.getElementById('side-subcategory').style.display = 'block'
      document.getElementById('side-cate' + cate.substring(0, 1)).insertAdjacentElement('afterend', document.getElementById('side-subcategory'))
      if (cate.length === 3) {
        state.subCategoryList.forEach(e => {
          if (e.code === cate) e.active = true
        })
      }
      state.choiceCategory = cate
      /*
      상품리스트 로딩
      */
      emit('send-message', cate)
    }
    const resetCate = (cate) => {
      if (state.choiceCategory.substring(0, 1) !== cate.substring(0, 1)) {
        state.categoryActive[state.choiceCategory.substring(0, 1)] = false
      }
      state.subCategoryList.forEach(e => {
        if (e.code === state.choiceCategory) e.active = false
      })
    }
    return {
      state, cateClick
    }
  }
}
</script>
<style>
#side-category > li {
  font-size:1.2em;
  cursor:pointer
}
#side-subcategory > li {
  font-size:1.0em;
  cursor:pointer
}
#side-category-mobile > li {
  font-size:1.0em;
  cursor:pointer;
}
.category-active {
  color: rgb(68, 138, 255);
}
</style>
