<template>
<div v-bind:class="{ 'modal-outer': !$store.state.priceAlarmModalPop }">
  <div class="modal-blind"></div>
  <div class="modal modal-price">
    <div class="modal-content text-center">
      <h2 class="fs-20 fw-bold">희망 가격 설정하기</h2>
      <div class="price-hope mg-t20">
        <input type="text" name="" id="" v-model="state.alarmPrice" @input="(e) => inputNumber(e)">
        <span>원</span>
      </div>
      <div class="mg-t20">
        <button type="button" class="btn btn-lg btn-primary" @click="regist"><i class="bi bi-check-lg"></i> 설정완료</button>
      </div>
      <div class="price-notice">
        <span class="text-blue">평균가: {{ commonUtil.numberformat(state.priceAverage) }}원</span> / <span class="text-green">현재가: {{ commonUtil.numberformat(state.product.lowestPrice) }}원</span>
      </div>
      <ul class="price-hope-list">
        <li @click="() => priceSetting(state.product.lowestPrice - 10)" style="cursor:pointer"><span><span class="text-green">현재가</span>보다 저렴한 가격:</span> <span class="fw-bold text-green">{{ commonUtil.numberformat(state.product.lowestPrice - 10) }}원</span></li>
        <li @click="() => priceSetting(state.priceAverage-10)" style="cursor:pointer"><span><span class="text-blue">평균가</span>보다 저렴한 가격:</span> <span class="fw-bold">{{ commonUtil.numberformat(state.priceAverage-10) }}원</span></li>
        <li @click="() => priceSetting(commonUtil.discountPercentCal(state.product.lowestPrice, 5))" style="cursor:pointer"><span><span class="text-green">현재가</span>보다 <span class="fw-bold">5%</span> 저렴한 가격:</span> <span class="fw-bold">{{ commonUtil.numberformat(commonUtil.discountPercentCal(state.product.lowestPrice, 5)) }}원</span></li>
        <li @click="() => priceSetting(commonUtil.discountPercentCal(state.product.lowestPrice, 10))" style="cursor:pointer"><span><span class="text-green">현재가</span>보다 <span class="fw-bold">10%</span> 저렴한 가격:</span> <span class="fw-bold">{{ commonUtil.numberformat(commonUtil.discountPercentCal(state.product.lowestPrice, 10)) }}원</span></li>
        <li @click="() => priceSetting(commonUtil.discountPercentCal(state.product.lowestPrice, 15))" style="cursor:pointer"><span><span class="text-green">현재가</span>보다 <span class="fw-bold">15%</span> 저렴한 가격:</span> <span class="fw-bold">{{ commonUtil.numberformat(commonUtil.discountPercentCal(state.product.lowestPrice, 15)) }}원</span></li>
        <li @click="() => priceSetting(state.lowestPrice)" style="cursor:pointer"><span class="text-red">역대 최저가:</span> <span class="fw-bold text-red">{{ commonUtil.numberformat(state.lowestPrice) }}원</span></li>
      </ul>
    </div>
    <button class="btn-icon modal-close-btn" @click="popClose"><i class="bi bi-x-lg"></i></button>
  </div>
</div>
</template>
<script>
import axios from '@/plugin/axios'
import { useStore } from 'vuex'
import commonUtil from '@/util/CommonUtil'
import { reactive } from 'vue'
export default {
  name: 'PriceAlarmModalView',
  setup (props) {
    let priceAlarmIdxnum = 0
    const API_SERVER = process.env.VUE_APP_API_SERVER
    const store = useStore()
    const state = reactive({
      product: {}, priceList: [], priceAverage: 0, alarmPrice: 0, lowestPrice: 0
    })
    const pop = async (product, lowestPrice, priceAverage) => {
      state.product = product
      state.priceAverage = priceAverage
      state.alarmPrice = 0
      state.lowestPrice = lowestPrice

      try {
        const result = await axios.get(API_SERVER + '/api/member/priceAlarm?productIdxnum=' + product.idxnum, {
          withCredentials: true, headers: { 'Content-Type': 'application/json' }
        })
        if (result.data.result === 'OK') {
          const priceAlarmDTO = result.data.priceAlarmDTO
          state.alarmPrice = priceAlarmDTO.price
          priceAlarmIdxnum = priceAlarmDTO.idxnum
          console.log(priceAlarmDTO.idxnum)
        }
      } catch (e) {
        if (e.response.status === 403) {
          store.commit('setModalPop', true)
        }
      }
    }
    const popClose = () => store.commit('setPriceAlarmModalPop', false)
    const priceSetting = (price) => {
      state.alarmPrice = price
    }
    const inputNumber = (e) => {
      const regex = /[^0-9]/g
      console.log(state.alarmPrice)
      state.alarmPrice = state.alarmPrice.replace(regex, '')
    }
    const regist = async () => {
      try {
        if (state.alarmPrice === 0 || state.alarmPrice >= state.product.lowestPrice) {
          alert('현재가보다 낮은 가격을 입력해주세요.')
          return
        }
        let sendData = { productIdxnum: state.product.idxnum, price: state.alarmPrice }
        if (priceAlarmIdxnum !== 0) sendData = { ...sendData, idxnum: priceAlarmIdxnum }
        const result = await axios.post(API_SERVER + '/api/member/priceAlarmRegist', sendData, {
          withCredentials: true, headers: { 'Content-Type': 'application/json' }
        })
        if (result.data.result === 'OK') {
          alert('희망가격 알림이 설정되었습니다.')
          store.commit('setPriceAlarmModalPop', false)
        } else {
          alert('일시적인 오류가 있습니다. 잠시후 다시 이용해주시기 바립니다.')
        }
      } catch (e) {
        if (e.response.status === 403) {
          store.commit('setPriceAlarmModalPop', false)
          store.commit('setModalPop', true)
        }
      }
    }
    return {
      popClose, pop, commonUtil, state, priceSetting, inputNumber, regist
    }
  }
}
</script>
