<template>
<div v-bind:class="{ 'modal-outer': !$store.state.myInfoModalPop }">
  <div class="modal-blind"></div>
  <div class="modal">
    <div class="modal-content text-center">
      <h2><img src="@/assets/images/logo_color.svg" alt=""></h2>
      <p class="fs-16 mg-t40">
        회원님의 쿠알람 로그인 정보입니다.<br/><br/>
      </p>
      <div style="text-align:left;font-size:1.3em;padding-left:30px">
        <div>EMAIL : <input type="email" v-model="state.email" class="custom-input"/> <button type="button" class="btn btn-small" @click="() => emailModify()" :disabled = "state.modifyEnable">수정</button></div>
        <div style="margin-top:10px">로그인 SNS : {{ state.sns }}</div>
      </div>
      <div style="margin-top:20px">
        &#8251; 이메일은 가격 변동 알림 발송을 위해 사용됩니다.
      </div>
      <div class="mg-t40">
        <!--button class="btn btn-lg btn-naver" @click="increase">네이버 메일 알림 받기</button-->
        <!--button class="btn btn-lg" @click="login">로그인</button-->
        <button class="btn btn-lg" @click="popClose">닫기</button>
      </div>
    </div>
    <button class="btn-icon modal-close-btn" @click="popClose"><i class="bi bi-x-lg"></i></button>
  </div>
</div>
</template>
<script>
import axios from '@/plugin/axios'
import { onMounted, reactive, computed } from 'vue'
import { useStore } from 'vuex'
import commonUtil from '@/util/CommonUtil'
export default {
  name: 'UserInfoLoginModalView',
  setup () {
    let tempEmail = ''
    const API_SERVER = process.env.VUE_APP_API_SERVER
    const store = useStore()
    const state = reactive({
      email: '', sns: '', oldEmail: '', modifyEnable: computed(() => { return !state.email || state.email.length === 0 || state.email === state.oldEmail })
    })
    const popClose = () => store.commit('setMyInfoModalPop', false)
    onMounted(() => {
      const payload = commonUtil.parseJwt(store.state.jwtToken)
      state.email = payload.email
      state.sns = payload.sns
      state.oldEmail = payload.email
    })
    const emailModify = async () => {
      tempEmail = state.oldEmail
      state.oldEmail = state.email
      try {
        const result = await axios.post(API_SERVER + '/api/member/emailUpdate', { email: state.email }, {
          withCredentials: true, headers: { 'Content-Type': 'application/json' }
        })
        if (!result.data.accessToken) {
          alert('기존 이메일과 같습니다.')
          return
        }
        store.commit('setJwtToken', result.data.accessToken)
        alert('이메일이 수정되었습니다.')
      } catch (e) {
        alert('이메일 수정에 실패했습니다. - ' + e.response.data.message)
        state.oldEmail = tempEmail
      }
    }
    return {
      popClose, state, emailModify
    }
  }
}
</script>
<style>
.custom-input {
  width: 60%;
  background-color: #fcfcfc;
  border: 0;
  border-bottom: 2px solid lightgrey;
  padding: 10px;
  font-size:1.1em
}
</style>
