<template>
  <main id="main" class="main">
    <div class="main-container-row">
      <SideKeyword/>
      <div class="content">
        <!-- 가격 변동 알림함 -->
        <section class="mg-t0">
          <div class="section-title">
            <h2 class="title">가격 변동 알림함</h2>
          </div>
          <div class="alarm-list">
            <div class="alarm-item" v-for="(priceAlarm, i) in state.priceAlarmList" :key="i">
              <div class="product-img">
                <router-link v-bind:to="'/detail/'+priceAlarm.productIdxnum"><img v-bind:src="priceAlarm.productDTO.preloadImage" alt="상품이미지" class="thumbnail"></router-link>
              </div>
              <div class="alarm-info">
                <div class="product-title mg-t0"><router-link v-bind:to="'/detail/'+priceAlarm.productIdxnum">{{ priceAlarm.productDTO.name }}</router-link></div>
                <div class="alarm-price">
                  <ul class="mg-b0">
                    <!--li class="price text-black">평균 가격 : 26,717,480</li-->
                    <li class="price text-black">알림 시각 : {{ commonUtil.dateFormatting3(priceAlarm.indate , 0) }}</li>
                    <li class="price text-red">희망 가격 : {{ commonUtil.numberformat(priceAlarm.targetPrice) }}</li>
                    <li class="price text-blue">알림 가격 : {{ commonUtil.numberformat(priceAlarm.currentPrice) }}</li>
                  </ul>
                </div>
                <div class="alarm-btn">
                  <button type="button" class="btn btn-primary">가격 추적 취소</button>
                  <router-link v-bind:to="'/detail/'+priceAlarm.productIdxnum"><button type="button" class="btn btn-secondary modal-price-show">희망 가격 변경</button></router-link>
                </div>
              </div>
            </div>
          </div>
          <!--nav class="pagination-container">
            <ul class="pagination">
              <li class="page-item disabled">
                <a class="page-link" href="#">
                  <span><i class="bi bi-caret-left-fill"></i></span>
                </a>
              </li>
              <li class="page-item active"><a class="page-link" href="#">1</a></li>
              <li class="page-item"><a class="page-link" href="#">2</a></li>
              <li class="page-item"><a class="page-link" href="#">3</a></li>
              <li class="page-item">
                <a class="page-link" href="#">
                  <span><i class="bi bi-caret-right-fill"></i></span>
                </a>
              </li>
            </ul>
          </nav-->
          <!-- 데이터 없음 -->
          <div class="no-data" v-if="!state.priceAlarmList.length">
            <span>
              <i class="bi bi-bag-x"></i>
              등록된 상품이 없습니다.
            </span>
          </div>
          <!-- End 데이터 없음 -->
        </section>
        <!-- End 가격 변동 알림함 -->
      </div>
    </div>
  </main>
</template>
<script>
import SideKeyword from './SideKeyword.vue'
import axios from '@/plugin/axios'
import commonUtil from '@/util/CommonUtil'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { reactive, onMounted } from 'vue'
export default {
  components: {
    SideKeyword
  },
  setup () {
    const store = useStore()
    const router = useRouter()
    const API_SERVER = process.env.VUE_APP_API_SERVER
    const state = reactive({
      priceAlarmList: []
    })
    onMounted(async () => {
      const jwtToken = store.state.jwtToken
      if (!commonUtil.expireCheckJwt(jwtToken)) {
        alert('로그인이 필요합니다.')
        router.replace({
          path: '/'
        })
        return
      }
      try {
        const result = await axios.get(API_SERVER + '/api/member/priceAlarmNoticeList', {
          withCredentials: true, headers: { 'Content-Type': 'application/json' }
        })
        state.priceAlarmList = result.data
      } catch (e) {
        if (e.response.status === 403) {
          router.replace({
            path: '/'
          })
        } else {
          alert('자료 불러오기에 실패했습니다.\n' + e.response.data.message + ' : ' + e.response.data.code)
        }
      }
    })
    return {
      state, commonUtil
    }
  }
}
</script>
