<template>
<div v-bind:class="{ 'modal-outer': !$store.state.modalPop }">
  <div class="modal-blind"></div>
  <div class="modal">
    <!--{{ $store.state.counter}} -->
    <!--{{ $store.getters.getTwoPowerCounter }} -->
    <div class="modal-content text-center">
      <h2><img src="../../assets/images/logo_color.svg" alt=""></h2>
      <p class="fs-16 mg-t40">
        쿠알람 회원에 가입하시고 다양한 알람과 혜택을 받으세요.<br/><br/>
        아직도 가격비교 없이 비싸게 구매하시나요? <br/>
        찜 상품 추가할인 시, 실시간으로 알림을 발송해 드릴게요.
      </p>
      <div class="mg-t40">
        <button class="btn btn-lg btn-naver" @click="naverLogin">네이버 로그인</button>
        <button class="btn btn-lg btn-kakao" @click="kakaoLogin">카카오 로그인</button>
      </div>
      <div style="margin-top:20px">
        &#8251; 네이버, 카카오에서 제공받은 이메일은 가격 변동 알림 발송을 위해 사용됩니다.
      </div>
    </div>
    <button class="btn-icon modal-close-btn" @click="popClose"><i class="bi bi-x-lg"></i></button>
  </div>
</div>
</template>
<script>
import axios from '@/plugin/axios'
import { useStore } from 'vuex'
export default {
  name: 'ModalView',
  setup () {
    const API_SERVER = process.env.VUE_APP_API_SERVER
    const serviceDomain = process.env.VUE_APP_SERVICE_DOMAIN
    const store = useStore()
    const popClose = () => store.commit('setModalPop', false)
    const kakaoLogin = () => {
      window.Kakao.Auth.authorize({ redirectUri: serviceDomain + '/oauth/kakao', scope: 'account_email,talk_message,openid' })
    }
    const naverLogin = async () => {
      try {
        const result = await axios.get(API_SERVER + '/api/public/naverGenerateRandomNum', {
          withCredentials: true, headers: { 'Content-Type': 'application/json' }
        })
        const naverState = result.data.state
        store.commit('naverState', naverState)
        const redirectUri = 'https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=M98W3Vw6P6Yb0z5BqpRb&redirect_uri=' + encodeURIComponent(serviceDomain + '/oauth/naver') + '&state=' + naverState
        window.location.href = redirectUri
      } catch (e) {
        alert('서버에서 응답이 없습니다.' + e.response.data.message)
      }
    }
    return {
      popClose, kakaoLogin, naverLogin
    }
  }
}
</script>
