<template>
  <main id="main" class="main">
    <div class="main-container-row">
      <SideKeyword/>
      <div class="content">
        <!-- 최근 본 상품 -->
        <section class="mg-t0">
          <div class="section-title">
            <h2 class="title">최근 본 상품</h2>
          </div>
          <div class="product-list">
            <div class="product-item" v-for="(recent , ii) in state.recentList" :key="ii">
              <div class="product-img">
                <router-link v-bind:to="'/detail/'+recent.productDTO.idxnum"><img v-bind:src="recent.productDTO.preloadImage" alt="상품이미지" class="thumbnail"></router-link>
                <span class="product-badge discount left-top" v-if="recent.productDTO.preDiscount > 1">이전대비 {{ recent.productDTO.preDiscount }}% <img src="@/assets/images/icon_down.svg" alt="하락"></span>
                <!--button class="btn-icon btn-text-white product-badge right-top"><i class="bi bi-x"></i></button-->
              </div>
              <div class="product-title"><router-link v-bind:to="'/detail/'+recent.productDTO.idxnum">{{ recent.productDTO.name }}</router-link></div>
              <div class="product-price-origin" v-if="recent.productDTO.originPrice > 0"><span class="discount">{{ recent.productDTO.discountPercent }}%</span> <span
                                        class="price-origin">{{ commonUtil.numberformat(recent.productDTO.originPrice) }}원</span></div>
              <div class="product-price" v-if="recent.productDTO.salePrice > 0 && recent.productDTO.couponPrice == 0"><span class="price">{{ commonUtil.numberformat(recent.productDTO.salePrice) }}</span>원</div>
              <div class="product-price" v-if="recent.productDTO.couponPrice > 0"><span class="price">{{ commonUtil.numberformat(recent.productDTO.couponPrice) }}</span>원</div>
              <div class="product-review"><span class="review-score-bg"><span class="review-score"
                      v-bind:style="{width: recent.productDTO.ratingAverage+'%'}"></span></span> <span class="review-count">({{ commonUtil.numberformat(recent.productDTO.ratingCnt) }})</span>
              </div>
              <div class="product-benefit">
                <ul>
                  <!--li>최대 4% 카드 즉시 할인</li-->
                  <li>최대 {{ recent.productDTO.saveMoney }}원 적립</li>
                </ul>
                <!--button type="button" class="btn-icon btn-zzim-list pd-0"><i class="bi bi-suit-heart-fill"></i></button-->
              </div>
            </div>
          </div>
          <!-- 데이터 없음 -->
          <div class="no-data" v-if="state.recentList.length === 0">
            <span>
              <i class="bi bi-bag-x"></i>
              최근 본 상품이 없습니다.
            </span>
          </div>
          <!-- End 데이터 없음 -->
        </section>
        <!-- End 최근 본 상품 -->

        <hr>

        <!-- 찜 보관함 -->
        <section class="mg-t0">
          <div class="tabmenu-wrap">
            <div class="tabmenu">
              <ul class="fs-20">
                <li><a href="javascript:void(0)" v-bind:class="{'active' : state.zzimFlag}" @click="() => toggleList(true)">찜 보관함</a></li>
                <li><a href="javascript:void(0)" v-bind:class="{'active' : !state.zzimFlag}" @click="() => toggleList(false)">가격 알림 상품</a></li>
              </ul>
            </div>
          </div>
          <div class="product-list" id="zzim_list" v-if="state.zzimFlag">
            <div class="product-item" :key="li" v-for="(zzim, li) in state.zzimList">
              <div class="product-img">
                <router-link v-bind:to="'/detail/'+zzim.productDTO.idxnum"><img v-bind:src="zzim.productDTO.preloadImage" alt="상품이미지" class="thumbnail"></router-link>
                <button class="btn-icon btn-text-white product-badge right-top" @click="() => deleteZzim(zzim.idxnum)"><i class="bi bi-x"></i></button>
              </div>
              <div class="product-title"><router-link v-bind:to="'/detail/'+zzim.productDTO.idxnum">{{ zzim.productDTO.name }}</router-link></div>
              <div class="product-price-origin" v-if="zzim.productDTO.originPrice > 0"><span class="discount">{{ zzim.productDTO.discountPercent }}%</span> <span
                                        class="price-origin">{{ commonUtil.numberformat(zzim.productDTO.originPrice) }}원</span></div>
              <div class="product-price" v-if="zzim.productDTO.salePrice > 0 && zzim.productDTO.couponPrice == 0"><span class="price">{{ commonUtil.numberformat(zzim.productDTO.salePrice) }}</span>원</div>
              <div class="product-price" v-if="zzim.productDTO.couponPrice > 0"><span class="price">{{ commonUtil.numberformat(zzim.productDTO.couponPrice) }}</span>원</div>
              <div class="product-category-list">등록일 : {{ commonUtil.dateFormatting2(zzim.indate, 0, '-') }}</div>
            </div>
          </div>
          <div class="product-list" id="alarm_list" v-if="!state.zzimFlag">
            <div class="product-item" :key="li" v-for="(priceAlarm, li) in state.priceAlarmList">
              <div class="product-img">
                <router-link v-bind:to="'/detail/'+priceAlarm.productDTO.idxnum"><img v-bind:src="priceAlarm.productDTO.preloadImage" alt="상품이미지" class="thumbnail"></router-link>
                <button class="btn-icon btn-text-white product-badge right-top" @click="() => deletePriceAlarm(priceAlarm.idxnum)"><i class="bi bi-x"></i></button>
              </div>
              <div class="product-title"><router-link v-bind:to="'/detail/'+priceAlarm.productDTO.idxnum">{{ priceAlarm.productDTO.name }}</router-link></div>
              <div class="product-price-origin"><span class="price text-black">{{ commonUtil.numberformat(priceAlarm.productDTO.lowestPrice) }}</span>원</div>
              <div class="product-price"><span class="price text-blue">{{ commonUtil.numberformat(priceAlarm.price) }}</span>원</div>
              <div class="product-category-list">등록일 : {{ commonUtil.dateFormatting2(priceAlarm.indate, 0, '-') }}</div>
            </div>
          </div>
          <!--nav class="pagination-container">
            <ul class="pagination">
              <li class="page-item disabled">
                <a class="page-link" href="#">
                  <span><i class="bi bi-caret-left-fill"></i></span>
                </a>
              </li>
              <li class="page-item active"><a class="page-link" href="#">1</a></li>
              <li class="page-item"><a class="page-link" href="#">2</a></li>
              <li class="page-item"><a class="page-link" href="#">3</a></li>
              <li class="page-item">
                <a class="page-link" href="#">
                  <span><i class="bi bi-caret-right-fill"></i></span>
                </a>
              </li>
            </ul>
          </nav-->
          <!-- 데이터 없음 -->
          <div class="no-data" v-if="!state.zzimList.length">
            <span>
              <i class="bi bi-bag-heart"></i>
              아직 찜한 상품이 없습니다.
            </span>
          </div>
          <!-- End 데이터 없음 -->
        </section>
        <!-- End 찜 보관함 -->
      </div>
    </div>
  </main>
</template>
<script>
import axios from '@/plugin/axios'
import { onMounted, reactive } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import commonUtil from '@/util/CommonUtil'
import SideKeyword from './SideKeyword.vue'
export default {
  components: {
    SideKeyword
  },
  setup () {
    const store = useStore()
    const router = useRouter()
    const API_SERVER = process.env.VUE_APP_API_SERVER
    const state = reactive({
      zzimList: [], zzimFlag: true, priceAlarmList: [], recentList: []
    })
    onMounted(async () => {
      const accessToken = store.state.jwtToken
      if (!commonUtil.expireCheckJwt(accessToken)) {
        alert('로그인이 필요합니다.')
        router.replace({
          path: '/'
        })
        return
      }
      try {
        const result = await axios.get(API_SERVER + '/api/member/zzimList', {
          withCredentials: true, headers: { 'Content-Type': 'application/json' }
        })
        state.zzimList = result.data.zzimList
        state.priceAlarmList = result.data.priceAlarmList
        state.recentList = result.data.recentList
      } catch (e) {
        if (e.response.status === 403) {
          router.replace({
            path: '/'
          })
        } else {
          alert('자료 불러오기에 실패했습니다.\n' + e.response.data.message + ' : ' + e.response.data.code)
        }
      }
    })
    const toggleList = async (flag) => {
      if (state.zzimFlag === flag) return
      state.zzimFlag = flag
    }
    const deleteZzim = async (idxnum) => {
      try {
        const result = await axios.get(API_SERVER + '/api/member/deleteZzim?idxnum=' + idxnum, {
          withCredentials: true, headers: { 'Content-Type': 'application/json' }
        })
        if (result.data.result === 'OK') {
          state.zzimList.forEach((e, i) => {
            if (e.idxnum === idxnum) state.zzimList.splice(i, 1)
          })
        } else {
          alert('처리에 문제가 있습니다. 잠시후 다시 이용해주세요')
        }
      } catch (e) {
        if (e.response.status === 401) {
          alert('삭제 권한이 없습니다.')
        } if (e.response.status === 403) {
          router.replace({
            path: '/'
          })
        }
      }
    }
    const deletePriceAlarm = async (idxnum) => {
      try {
        const result = await axios.get(API_SERVER + '/api/member/deletePriceAlarm?idxnum=' + idxnum, {
          withCredentials: true, headers: { 'Content-Type': 'application/json' }
        })
        if (result.data.result === 'OK') {
          state.priceAlarmList.forEach((e, i) => {
            if (e.idxnum === idxnum) state.priceAlarmList.splice(i, 1)
          })
        } else {
          alert('처리에 문제가 있습니다. 잠시후 다시 이용해주세요')
        }
      } catch (e) {
        if (e.response.status === 401) {
          alert('삭제 권한이 없습니다.')
        } if (e.response.status === 403) {
          router.replace({
            path: '/'
          })
        }
      }
    }
    return {
      state, commonUtil, deleteZzim, toggleList, deletePriceAlarm
    }
  }
}
</script>
