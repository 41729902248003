<template>
  <aside class="aside">
        <div class="keyword-recent">
          <h2 class="keyword-title">최근 검색어</h2>
          <ul>
            <li v-for="(keyword , i) in state.myKeyword" :key="i"><a href="javascript:void(0)" @click="() => search(keyword.keyword)" class="text-truncate">{{ keyword.keyword }}</a><button class="btn-icon"><!--i class="bi bi-x"></i--></button></li>
          </ul>
        </div>
        <div class="kerword-rank">
          <h2 class="keyword-title">인기 검색어</h2>
          <ol>
            <li v-for="(keyword , i) in state.bestKeyword" :key="i"><span class="rank-number">{{ i+1 }}</span><a href="javascript:void(0)" @click="() => search(keyword.keyword)" class="text-truncate">{{ keyword.keyword }}</a></li>
          </ol>
        </div>
  </aside>
</template>
<script>
import axios from '@/plugin/axios'
import { onMounted, reactive } from 'vue'
import { useRouter } from 'vue-router'
export default {
  setup () {
    const API_SERVER = process.env.VUE_APP_API_SERVER
    const router = useRouter()
    const state = reactive({
      bestKeyword: [], myKeyword: []
    })
    onMounted(() => {
      searchKeywordLoad()
    })
    const searchKeywordLoad = async () => {
      const result = await axios.get(API_SERVER + '/api/public/keywordList', {
        withCredentials: true, headers: { 'Content-Type': 'application/json' }
      })
      state.myKeyword = result.data.my
      state.bestKeyword = result.data.best
    }
    const search = (keyword) => {
      router.push({ path: '/search/' + keyword })
    }
    return {
      state, search
    }
  }
}
</script>
