<template>
  <main id="main" class="main">
    <div class="main-container-row">
      <div class="content">
        <!-- 최근 본 상품 -->
        <rocket-header-view/>
        <section class="mg-t0">
          <div class="section-title">
            <h2>지금 구매하기 좋은 상품 추천합니다.</h2>
          </div>
          <div class="product-list">
            <div class="product-item" :key="ii" v-for="(product, ii) in state.productList">
                        <div class="product-img">
                            <router-link v-bind:to="'/detail/'+product.idxnum"><img
                                    v-bind:src="product.preloadImage"
                                    alt="상품이미지" class="thumbnail"></router-link>
                            <span class="product-badge discount left-top" v-if="product.preDiscount > 3">{{ product.preDiscount }}% <img src="../../assets/images/icon_down.svg"
                                    alt="하락"></span>
                            <!--span class="product-badge slodout right-top">품절임박</span-->
                        </div>
                        <div class="product-title"><router-link v-bind:to="'/detail/'+product.idxnum" target="_blank">{{ product.name }}</router-link></div>
                        <div class="product-price-origin" v-if="product.originPrice > 0"><span class="discount" v-if="product.discountPercent > 0">{{ product.discountPercent }}%</span> <span
                                        class="price-origin">{{ commonUtil.numberformat(product.originPrice) }}원</span></div>
                        <div class="product-price" v-if="product.salePrice > 0 && product.couponPrice == 0"><span class="price">{{ commonUtil.numberformat(product.salePrice) }}</span>원</div>
                        <div class="product-price" v-if="product.couponPrice > 0"><span class="price">{{ commonUtil.numberformat(product.couponPrice) }}</span>원</div>
                        <div class="product-review"><span class="review-score-bg"><span class="review-score"
                                        v-bind:style="{width: product.ratingAverage+'%'}"></span></span> <span class="review-count">({{ commonUtil.numberformat(product.ratingCnt) }})</span></div>
                        <!--div class="product-price-change">450원 하락, 하락추세, 3일간 하락률 3%</div>
                        <div class="product-category-list">생활용품 > 헤어/바디/세안</div-->
                        <div class="product-benefit">
                          <ul>
                            <!--li>최대 4% 카드 즉시 할인</li-->
                            <li>최대 {{ commonUtil.numberformat(product.saveMoney) }}원 적립</li>
                          </ul>
                          <!--button type="button" v-bind:class="{'btn-icon': true, 'btn-zzim-list': true, 'btn-zzim-list-active': product.zzim}" @click="() => zzim(product)">
                            <i class="bi bi-suit-heart-fill"></i> <span class="zzim-text">찜</span>
                          </button-->
                        </div>
                    </div>
          </div>
          <div id="sentinel"></div>
          <!-- 데이터 없음 -->
          <!-- End 데이터 없음 -->
        </section>
        <!-- End 최근 본 상품 -->
       </div>
    </div>
  </main>
</template>
<script>
import axios from '@/plugin/axios'
import { reactive, onMounted } from 'vue'
import commonUtil from '@/util/CommonUtil'
import RocketHeaderView from './RocketHeaderView.vue'
export default {
  components: {
    RocketHeaderView
  },
  setup () {
    const API_SERVER = process.env.VUE_APP_API_SERVER
    const state = reactive({
      productList: []
    })
    onMounted(async () => {
      const result = await axios.get(API_SERVER + '/api/public/eventList', {
        withCredentials: true, headers: { 'Content-Type': 'application/json' }
      })
      state.productList = result.data
    })
    return {
      state, commonUtil
    }
  }
}
</script>
