<template>
  <main id="main" class="main">
    <div class="main-container" v-show="state.product.detailImage">
      <section class="mg-t10">
        <div class="detail-container">
          <div class="product-img">
            <a v-bind:href="state.coupangLink" target="_blank"><img
              v-bind:src="state.product.detailImage"
              alt="상품이미지" class="thumbnail">
            <span class="product-badge discount left-top" v-if="state.product.preDiscount > 0">이전대비 {{ state.product.preDiscount }}% <img src="../../assets/images/icon_down.svg"
                alt="하락"></span>
            </a>
          </div>
          <div class="product-detail-info">
            <div class="product-share">
              <button class="btn-icon-circle btn-share-show"><i class="bi bi-share-fill" @click="() => shareOption()"></i></button>
              <div class="product-share-option" :style="{'display': state.shareView}">
                <a href="#"><img src="../../assets/images/btn_kakaotalk.svg" alt="카카오톡"></a>
                <a href="#"><img src="../../assets/images/btn_instagram.svg" alt="인스타그램" style="margin-top:10px"></a>
              </div>
            </div>
            <div class="product-title fs-20"><a v-bind:href="state.coupangLink" target="_blank">{{ state.product.name }}</a></div>
            <div class="product-option" style="height:10px">
              <!--select name="" id="">
                <option value="">골드</option>
                <option value="">실버</option>
                <option value="">블랙</option>
                <option value="">그레이</option>
              </select-->
            </div>
            <div class="product-price-origin" v-if="state.product.originPrice > 0"><a v-bind:href="state.coupangLink" target="_blank"><span class="discount">{{ state.product.discountPercent }}%</span> <span
                class="price-origin">{{ commonUtil.numberformat(state.product.originPrice) }}원</span></a></div>
            <div class="product-price" v-if="state.product.salePrice > 0 && state.product.couponPrice == 0"><a v-bind:href="state.coupangLink" target="_blank"><span class="price">{{ commonUtil.numberformat(state.product.salePrice) }}</span>원</a></div>
            <div class="product-price" v-if="state.product.couponPrice > 0"><a v-bind:href="state.coupangLink" target="_blank"><span class="price">{{ commonUtil.numberformat(state.product.couponPrice) }}</span>원</a></div>
            <div class="product-dc-rate fs-16" v-if="state.product.preDiscount > 0">이전대비 {{ state.product.preDiscount }}% 싼가격이에요</div>
            <div class="product-review"><a v-bind:href="state.coupangLink" target="_blank"><span class="review-score-bg"><span class="review-score"
              v-bind:style="{width: state.product.ratingAverage+'%'}"></span></span></a> <a v-bind:href="state.coupangLink" target="_blank"><span class="review-count">({{ commonUtil.numberformat(state.product.ratingCnt) }})</span></a></div>
            <div class="product-benefit fs-14">
              <ul>
                <!--li>최대 4% 카드 즉시 할인 (우리,국민,신한)</li-->
                <li><a v-bind:href="state.coupangLink" target="_blank">최대 {{ commonUtil.numberformat(state.product.saveMoney) }}원 적립</a></li>
              </ul>
            </div>
            <div class="product-detail-btn mg-t20">
              <a v-bind:href="state.coupangLink" target="_blank" class="btn btn-lg btn-primary">리뷰 확인하기</a>
              <a v-bind:href="state.coupangLink" target="_blank" class="btn btn-lg btn-secondary">구매 하러가기</a>
              <button v-bind:class="{ btn: true, 'btn-lg': true, 'btn-primary-outline': true, 'btn-primary-outline-checked': state.product.zzim }" @click="() => zzim(state.product)" style="background: none;border-color: var(--color-primary-hover),color: var(--color-primary-hover)"><i class="bi bi-suit-heart-fill"></i> 찜</button>
            </div>
            <div style="margin-top:10px">
              <span>이 가격정보는 쿠팡 파트너스 활동의 일환으로, 이에 따른 일정액의 수수료를 제공받습니다.</span>
            </div>
            <h2 class="fs-16 fw-bold mg-t30 mg-b10">지금 구매해도 될까요?</h2>
            <div class="price-level">
              <span class="price-level-point" v-bind:style="{left: recommendPercent(state.product.recommendFlag)+'%'}"></span> <!-- 9%, 29%, 49%, 69%, 89% -->
              <ul class="price-level-color">
                <li>신의 경지</li>
                <li>현명한 소비</li>
                <li>평균 구매가</li>
                <li>기다려봐요</li>
                <li>사지마세요</li>
              </ul>
            </div>
            <div class="fs-16 mg-t20" v-if="state.product.recommendFlag > 3">
              현재 가격은 소비자 평균 구매가격인 <span class="text-number fw-bold ">{{ commonUtil.numberformat(state.priceAverage) }}</span>원보다 비싼 <span
                class="text-number fw-bold ">{{ commonUtil.numberformat(state.product.lowestPrice) }}</span>원으로 가격추이를 지켜보시길 추천합니다.
            </div>
            <div class="fs-16 mg-t20" v-if="state.product.recommendFlag == 3">
              현재 가격은 소비자 평균 구매가격인 <span class="text-number fw-bold ">{{ commonUtil.numberformat(state.priceAverage) }}</span>원과 비슷한 <span
                class="text-number fw-bold ">{{ commonUtil.numberformat(state.product.lowestPrice) }}</span>원으로 구매하기 적합한 시기입니다.
            </div>
            <div class="fs-16 mg-t20" v-if="state.product.recommendFlag < 3 ">
              현재 가격은 소비자 평균 구매가격인 <span class="text-number fw-bold ">{{ commonUtil.numberformat(state.priceAverage) }}</span>원보다 싼 <span
                class="text-number fw-bold ">{{ commonUtil.numberformat(state.product.lowestPrice) }}</span>원으로 구매를 적극 추천합니다.
            </div>
            <div class="product-detail-btn mg-t20">
              <button class="btn btn-lg btn-primary-outline modal-price-show" @click="priceAlarm"><i class="bi bi-bell-fill"></i> 희망가격 설정 후 알림
                받기</button>
            </div>
          </div>
        </div>
      </section>
    </div>
    <!-- End 상품 상세정보 -->
    <hr>
    <!-- 그래프 -->
    <div class="main-container">
      <section>
        <div class="section-title">
          <h2 class="title"><span style="font-size:2.0em;color:#e99923">쿠</span><span style="font-size:2.0em;color:#50a3d9">팡</span> 가격 추적 그래프</h2>
        </div>
        <div>
          <Line id="my-chart-id" :options="chartOptions" :data="state.chartData" v-if="state.loaded"/>
        </div>
      </section>
    </div>
    <!-- End 그래프 -->
    <hr>
    <!-- 함께 비교하면 좋은 상품 -->
    <div class="main-container">
      <section>
        <div class="section-title">
          <h2 class="title">함께 비교하면 좋은 상품</h2>
        </div>
        <div class="swiper-ouside">
          <swiper class="swiper p1Swiper swiper-wrapper" v-bind="swiperOptions" :modules="modules" @swiper="onSwiper" @slideChange="onSlideChange">
              <swiper-slide :key="ii" v-for="(product, ii) in state.recommendList" class="product-item swiper-slide">
                <div class="product-img">
                  <router-link v-bind:to="'/detail/'+product.idxnum"><img
                      v-bind:src="product.preloadImage"
                      alt="상품이미지" class="thumbnail"></router-link>
                      <span class="product-badge discount right-top" v-if="product.preDiscount > 1">이전대비 {{ product.preDiscount }}% <img
                                                src="../../assets/images/icon_down.svg" alt="하락"></span>
                </div>
                <div class="product-title"><router-link v-bind:to="'/detail/'+product.idxnum">{{product.name}}</router-link></div>
                <div class="product-price-origin" v-if="product.originPrice > 0"><span class="discount" v-if="product.discountPercent > 0">{{ product.discountPercent }}%</span> <span
                                        class="price-origin">{{ commonUtil.numberformat(product.originPrice) }}원</span></div>
                <div class="product-price" v-if="product.salePrice > 0 && product.couponPrice == 0"><span class="price">{{ commonUtil.numberformat(product.salePrice) }}</span>원</div>
                <div class="product-price" v-if="product.couponPrice > 0"><span class="price">{{ commonUtil.numberformat(product.couponPrice) }}</span>원</div>
                <div class="product-review"><span class="review-score-bg"><span class="review-score"
                              v-bind:style="{width: product.ratingAverage+'%'}"></span></span> <span class="review-count">({{ commonUtil.numberformat(product.ratingCnt) }})</span>
                </div>
                <div class="product-benefit">
                  <ul>
                    <!--li>최대 4% 카드 즉시 할인</li-->
                    <li>최대 {{ commonUtil.numberformat(product.saveMoney) }}원 적립</li>
                  </ul>
                  <button type="button" v-bind:class="{'btn-icon': true, 'btn-zzim-list': true, 'btn-zzim-list-active': product.zzim}" @click="() => zzim(product)"><i class="bi bi-suit-heart-fill"></i> <span
                      class="zzim-text">찜</span></button>
                </div>
              </swiper-slide>
        </swiper>
        <div class="swiper-button-next swiper-button-next-circle swiper-button-next1"></div>
        <div class="swiper-button-prev swiper-button-prev-circle swiper-button-prev1"></div>
        <div class="swiper-pagination swiper-pagination1"></div>
      </div>
      <div class="mg-t40 text-center">
        <router-link :to="'/list/' + state.product.category" class="btn btn-lg btn-primary"><i class="bi bi-zoom-in"></i> 해당 카테고리 상품 더보기</router-link>
      </div>
    </section>
  </div>
</main>
<PriceAlarmModalView ref="priceAlarmModal"/>
</template>
<script>
import axios from '@/plugin/axios'
import { onMounted, reactive, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import commonUtil from '@/util/CommonUtil'
import PriceAlarmModalView from '@/main/views/PriceAlarmModalView.vue'
import { Line } from 'vue-chartjs'
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import { Navigation, Pagination, Mousewheel, Keyboard } from 'swiper/modules'
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend)
export default {
  name: 'DetailView',
  components: { Line, Swiper, SwiperSlide, PriceAlarmModalView },
  setup (props, context) {
    const priceAlarmModal = ref(null)
    const state = reactive({
      product: {}, coupangLink: '', priceList: [], loaded: false, chartData: {}, priceAverage: 0, lowestPrice: 0, recommendList: [], zzimClass: { btn: true, 'btn-lg': true, 'btn-primary-outline': true }, shareView: 'none'
    })
    const store = useStore()
    const route = useRoute()
    const loginStatus = store.getters.getLoginStatus
    let idxnum = route.params.idxnum
    const API_SERVER = process.env.VUE_APP_API_SERVER
    const swiperOptions = {
      slidesPerView: 4,
      spaceBetween: 30,
      mousewheel: true,
      pagination: { clickable: false, el: '.swiper-pagination1' },
      breakpoints: { 10: { slidesPerView: 1 }, 300: { slidesPerView: 2 }, 760: { slidesPerView: 4 } },
      keyboard: true,
      navigation: {
        nextEl: '.swiper-button-next1',
        prevEl: '.swiper-button-prev1'
      }
    }
    watch(route, (cur, pre) => {
      idxnum = route.params.idxnum
      dataLoading()
    })
    onMounted(() => {
      dataLoading()
    })
    const dataLoading = async () => {
      try {
        const result = await axios.get(API_SERVER + '/api/public/productDetail?idxnum=' + idxnum, {
          withCredentials: true, headers: { 'Content-Type': 'application/json' }
        })
        state.product = result.data.product
        state.priceList = result.data.priceHistory
        state.priceAverage = result.data.average
        state.lowestPrice = result.data.lowest
        state.recommendList = result.data.recommend
        state.chartData = {
          labels: result.data.dateSet,
          datasets: [{ label: '쿠팡 가격 변동', backgroundColor: '#f87979', data: result.data.priceSet }]
        }
        state.loaded = true
        state.coupangLink = API_SERVER + '/api/public/coupangLink/' + state.product.productId + '/' + state.product.itemId + '/' + state.product.vendorItemId
        if (Object.entries(store.state.ptnInfo).length !== 0 && commonUtil.expireCheck(store.state.ptnInfo.expire)) {
          state.coupangLink = state.coupangLink + '/' + store.state.ptnInfo.ptn
        }
        window.scrollTo(0, 0)
      } catch (e) {
        alert('자료 불러오기에 실패했습니다.\n' + e.response.data.message + ' : ' + e.response.data.code)
      }
    }
    function recommendPercent (flag) {
      switch (flag) {
        case 5:
          return 89
        case 4:
          return 69
        case 3:
          return 49
        case 2:
          return 29
        case 1:
          return 9
        default:
          return 49
      }
    }
    const zzim = async (product) => {
      if (!loginStatus) {
        store.commit('setModalPop', true)
        return
      }
      try {
        const result = await axios.get(API_SERVER + '/api/member/zzim?productIdxnum=' + product.idxnum, {
          withCredentials: true, headers: { 'Content-Type': 'application/json' }
        })
        if (result.data.result === 'PRESENT') {
          alert('이미 찜 등록됐습니다.')
        } else if (result.data.result === 'ADD') {
          product.zzim = true
          alert('찜 등록됐습니다.')
        }
      } catch (e) {
        if (e.response.status === 403) {
          store.commit('setModalPop', true)
        }
      }
    }
    const priceAlarm = () => {
      if (!loginStatus) {
        store.commit('setModalPop', true)
        return
      }
      alert('희망가격 알람은 2월 26일부터 제공될 예정입니다. 미리 설정해놓으시면 26일 이후 목표가격 도달시 메일로 알려드립니다. ')
      priceAlarmModal.value.pop(state.product, state.lowestPrice, state.priceAverage)
      store.commit('setPriceAlarmModalPop', true)
    }
    const onSwiper = (swiper) => {
      /*
      console.log(swiper)
      */
    }
    const onSlideChange = () => {
      console.log('slide change')
    }
    const shareOption = () => {
      state.shareView = 'block'
    }
    return {
      chartOptions: {
        responsive: true, maintainAspectRatio: false
      },
      state,
      commonUtil,
      idxnum,
      recommendPercent,
      onSwiper,
      onSlideChange,
      swiperOptions,
      modules: [Navigation, Pagination, Mousewheel, Keyboard],
      zzim,
      priceAlarm,
      priceAlarmModal,
      shareOption
    }
  }
}
</script>
<style scoped>
.swiper-button-next-circle {
  margin-top: 0px;
  position: absolute;
  top: 50%;
  right: -30px;
  width: 45px;
  height: 45px;
  transform: translateY(-50%);
}
.swiper-button-prev-circle {
  position: absolute;
  top: 50%;
  left: -30px;
  width: 45px;
  height: 45px;
  transform: translateY(-50%);
  margin-top: 0px;
}
.swiper-button-next-circle,
.swiper-button-prev-circle {
border: 1px solid #e9e9e9;
border-radius: 50%;
background: #fff;
box-shadow: 0px 3px 2px rgba(0,0,0,.1);
}
.swiper-button-next-circle::after,
.swiper-button-prev-circle::after {
  font-size: 24px;
}
.swiper-button-next.swiper-button-disabled, .swiper-button-prev.swiper-button-disabled {
  opacity: 0;
}

@media screen and (max-width: 768px) {
  .swiper-button-next-circle, .swiper-button-prev-circle {
    display: none;
  }
}
</style>
